export interface ExtractRequest {
    text: string;
    history: ChatBotConversation[];
    matched_entities: EntityMatch[];
    negated_entities: EntityMatch[];
    entities_to_match: MatchableEntities;
    intent: string;
    levels_by_skills?: any; // todo need?
    user_subsectors: string[];
    user_sectors: string[];
    removed_entities: string[];
}

export interface EntityMatch {
    matched_text: string;
    entity_type: string;
    start_index: number;
    end_index: number;
}

export interface ChatBotConversation {
    text: string;
    source: "bot" | "user";
    entities?: EntityMatch[];
    date: number;
    bot_response?: BotResponseType;
}

export enum BotResponseType {
    GET_AVAILABILITY_FINISH = "Sure! Here are the available contacts I have found matching your request.",
    GET_AVAILABILITY_ASK_FOR_SKILLS = "What skills do you need?",
    GET_AVAILABILITY_ASK_FOR_DATES = "Which date(s)?",
    GET_AVAILABILITY_ASK_FOR_TIME = "You can specify a time preset (AM Only, PM Only, All Day) or just tell me a start and end time (e.g. 8:30AM to 3:30PM)",
    GET_AVAILABILITY_ASK_FOR_EXACT_SKILLS = "What exact skills do you need?"
}

export interface MatchableEntities {
    presets: string[];
    agencies: string[];
    skills: string[];
    subsectors: string[];
    sectors: string[];
    teams: string[];
    groups: string[];
    names: string[];
    dates: string[];
}

export interface ChatBotAvailabilityFilters {
    preset?: string;
    team?: string;
    group?: string;
    sort_order?: string;
    times: string[];
    dates: string[];
    skills: string[];
    subSectors: string[];
    sectors: string[];
    agencies: string[];
    names: string[];
}

export interface ExtractResponse {
    intent: string;
    entities: EntityMatch[];
    history: ChatBotConversation[];
    matched_entities: EntityMatch[];
    negated_entities: EntityMatch[];
    normalized_availability_filters: ChatBotAvailabilityFilters;
}