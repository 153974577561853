/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { AutocompleteSuggestion } from '../../components/organisation/OrganisationAutoCompleteSuggestions';
import {
    CreateOrganisationRequest,
    UpdateOrganisationRequest,
    UpdateOrganisationApiRequest,
    SetOrganisationTeamMembersRequest,
    InviteSuggestionDto
} from './RequestTypes';
import {
    LogoSuggestionDTO,
    OrganisationDTO,
    APIImplementersDTO,
    OrganisationTeamDTO,
    OrganisationUser,
    ExternalOrganisationLogoRecord,
    UserOrgSuggestionDTO,
    MergedOrganisationDTO,
    PossibleOrgMatch
} from './ResponseTypes';
import { RepresentedByOrganisation } from '../availability/ResponseTypes';

const apiUrls = {
    getAll() {
        return `${AppConfig.Settings.api.externalUri}/organisations`;
    },
    createOrganisation() {
        return `${AppConfig.Settings.api.externalUri}/organisations`;
    },
    updateOrganisation() {
        return `${AppConfig.Settings.api.externalUri}/organisations`;
    },
    getOrganisation(id: OrganisationDTO['id']) {
        return `${AppConfig.Settings.api.externalUri}/organisations/byid/${id}`;
    },
    getOwnOrganisation() {
        return `${AppConfig.Settings.api.externalUri}/organisations/byid`;
    },
    getOrganisationAutocomplete() {
        return `${AppConfig.Settings.api.externalUri}/organisations/autoCompleteByEmail`;
    },
    getLogoSuggestions(name: string) {
        return `${AppConfig.Settings.api.externalUri}/organisations/logoSuggestions/${name}`;
    },
    deleteOrganisation(id: OrganisationDTO['id']) {
        return `${AppConfig.Settings.api.externalUri}/organisations/${id}`;
    },
    //TODO: Implement
    // isVerified() {
    //     return `${AppConfig.Settings.api.externalUri}/organisations/${id}`;
    // },
    updateApiInfo() {
        return `${AppConfig.Settings.api.externalUri}/organisations/api-info`;
    },
    // setLocation() {
    //     return `${AppConfig.Settings.api.externalUri}/organisations/setDefaultLocation`;
    // },
    updateDefaultLocation() {
        return `${AppConfig.Settings.api.externalUri}/organisations/setDefaultLocation`;
    },
    tryVerifyOrganisation() {
        return `${AppConfig.Settings.api.externalUri}/organisations/verify`;
    },
    getTeams() {
        return `${AppConfig.Settings.api.externalUri}/organisation/teams`;
    },
    getTeam(id: OrganisationTeamDTO['id']) {
        return `${AppConfig.Settings.api.externalUri}/organisation/teams/${id}`;
    },
    setTeamMembers(id: OrganisationTeamDTO['id']) {
        return `${AppConfig.Settings.api.externalUri}/organisation/teams/${id}/members`;
    },
    inviteMember(email: string) {
        return `${AppConfig.Settings.api.externalUri}/invite/colleague?email=${email}`;
    },
    getUsers(id: OrganisationDTO['id']) {
        return `${AppConfig.Settings.api.externalUri}/organisations/${id}/users`;
    },
    getAPIImplementersUserLookup() {
        return `${AppConfig.Settings.api.externalUri}/organisations/apiimplementers/1`;
    },
    respondToInviteSuggestion() {
        return `${AppConfig.Settings.api.externalUri}/organisations/membership`;
    },
    getAllExternalOrganisationLogos() {
        return `${AppConfig.Settings.api.externalUri}/organisations/external/logo`;
    },
    updateExternalOrganisationLogo(domain) {
        return `${AppConfig.Settings.api.externalUri}/organisations/external/logo/${domain}`;
    },
    getSuggestionsByEmail(email: string) {
        return `${AppConfig.Settings.api.nodeApiUri}/suggest/byEmail/${email}?onlyCache=false`;
    },
    uploadImageToExternalOrg(domain: string) {
        return `${AppConfig.Settings.api.externalUri}/organisations/external/logo/${domain}/upload`;
    },
    fetchExternalImage(url: string) {
        return `${AppConfig.Settings.api.nodeApiUri}/users/image/?url=${url}`;
    },
    getRepresentedByOrganisations(listId: string) {
        return `${AppConfig.Settings.api.externalUri}/organisations/representing-agencies?listId=${listId}`;
    },
    searchOrganisationsByName(name: string) {
        return `${AppConfig.Settings.api.externalUri}/organisations/search/${name}`;
    }
};

const apiCalls = {
    searchOrganisationsByName(name: string) {
        return afetch.request<null, MergedOrganisationDTO[]>(
            apiUrls.searchOrganisationsByName(name),
            'GET',
            null,
            'Unable to get organizations'
        );
    },
    finalize() {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/organisations/finalize`,
            'POST',
            null,
            'Unable to cache organization'
        );
    },
    getOrganisationByDomain(domain: string) {
        return afetch.request<null, PossibleOrgMatch>(
            `${AppConfig.Settings.api.externalUri}/organisations/byDomain/${domain}`,
            'GET',
            null,
            'Unable to get organization'
        );
    },
    requestToJoinOrganisation(organisationId: string) {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/organisations/${organisationId}/join`,
            'PUT',
            null,
            'Unable to join organization'
        );
    },
    getRepresentedByOrganisations(listId: string) {
        return afetch.request<null, RepresentedByOrganisation[]>(
            apiUrls.getRepresentedByOrganisations(listId),
            'GET',
            null,
            'Unable to get representing agencies'
        );
    },
    getExtOrgPicture(id: string): string { 
        return AppConfig.Settings.api.externalUri + "/organisations/external/logo/" + id + "?api-version=1.0" 
    },
    getAll(term?: string, filter?: 'agency' | 'hirer', verified = true) {
        const params = new URLSearchParams();
        if (term) params.append('term', term);
        if (filter) params.append('filter', filter);
        params.append('verified', verified.toString());
        const url = `${apiUrls.getAll()}?${params.toString()}`;
        //console.log('URL', url);
        return afetch.request<null, { data: OrganisationDTO[] } | null>(
            url,
            'GET',
            null,
            'Unable to get organizations'
        );
    },
    createOrganisation(organisation: CreateOrganisationRequest) {
        return afetch.request<CreateOrganisationRequest, OrganisationDTO['id']>(
            apiUrls.createOrganisation(),
            'POST',
            organisation,
            'Unable to create organization',
            undefined,
            true
        );
    },
    getAPIImplementersUserLookup() {
        return afetch.request<null, APIImplementersDTO[]>(
            apiUrls.getAPIImplementersUserLookup(),
            'GET',
            null,
            'Unable to get api implementers',
            undefined,
            true
        );
    },
    uploadImageToExternalOrg(domain: string, base64Img: string) {
        return afetch.request<{
            imageBase64: string;
        }, null>(
            apiUrls.uploadImageToExternalOrg(domain),
            'PUT',
            {
                imageBase64: base64Img
            },
            'Unable to upload image',
            undefined,
            true
        );
    },
    updateOrganisation(organisation: UpdateOrganisationRequest) {
        return afetch.request<UpdateOrganisationRequest, OrganisationDTO['id']>(
            apiUrls.updateOrganisation(),
            'PUT',
            organisation,
            'Unable to update organization'
        );
    },
    getOrganisation(id: string) {
        return afetch.request<null, { data: OrganisationDTO }>(
            apiUrls.getOrganisation(id),
            'GET',
            null,
            'Unable to get organization'
        );
    },
    getOwnOrganisation() {
        return afetch.request<null, { data: OrganisationDTO }>(
            apiUrls.getOwnOrganisation(),
            'GET',
            null,
            'Unable to get organisation'
        );
    },
    getOrganisationAutocomplete() {
        return afetch.request<null, AutocompleteSuggestion>(
            apiUrls.getOrganisationAutocomplete(),
            'GET',
            null,
            'Unable to get organization'
        );
    },
    deleteOrganisation(id: OrganisationDTO['id']) {
        return afetch.request<null, null>(
            apiUrls.deleteOrganisation(id),
            'DELETE',
            null,
            'Unable to delete organization'
        );
    },
    getLogoSuggestions(name: string) {
        return afetch.request<string, { data: LogoSuggestionDTO[] }>(
            apiUrls.getLogoSuggestions(name),
            'GET',
            null,
            `Unable to get logo suggestions for ${name}`
        );
    },
    updateApiInfo(payload: UpdateOrganisationApiRequest) {
        return afetch.request<UpdateOrganisationApiRequest>(
            apiUrls.updateApiInfo(),
            'POST',
            payload,
            'Unable to update API credentials'
        );
    },
    getAllExternalOrganisationLogos() {
        return afetch.request<null, ExternalOrganisationLogoRecord[]>(
            apiUrls.getAllExternalOrganisationLogos(),
            'GET',
            null,
            'Unable to get logos'
        );
    },
    updateExternalOrganisationLogo(meta: ExternalOrganisationLogoRecord) {
        return afetch.request<ExternalOrganisationLogoRecord, null>(
            apiUrls.updateExternalOrganisationLogo(meta.url),
            'PUT',
            meta,
            'Unable to update logo'
        );
    },
    updateLocation(
        locationPlaceId: google.maps.places.PlaceResult['place_id'],
        fullAddress: string,
        extraDetails: string,
        locationPlaceComponents: google.maps.places.PlaceResult['address_components']
    ) {
        return afetch.request<
            {
                locationPlaceId: google.maps.places.PlaceResult['place_id'],
                locationPlaceName: string
                LocationFriendlyName: string,
                LocationFriendlyAddress: string,
                locationPlaceComponents: google.maps.places.PlaceResult['address_components']
            },
            OrganisationDTO['id']
        >(
            apiUrls.updateDefaultLocation(),
            'POST',
            {
                locationPlaceId,
                locationPlaceName: fullAddress,
                LocationFriendlyName: extraDetails,
                LocationFriendlyAddress: fullAddress,
                locationPlaceComponents: locationPlaceComponents
            },
            'Unable to update the organizations location'
        );
    },
    getTeams() {
        return afetch.request<null, { data: OrganisationTeamDTO[] }>(
            apiUrls.getTeams(),
            'GET',
            null,
            'Unable to get teams'
        );
    },
    fetchExternalImage(url: string) {
        return apiUrls.fetchExternalImage(url)
    },
    tryVerifyOrganisation: () => {
        return afetch.request<null, boolean>(
            apiUrls.tryVerifyOrganisation(),
            'GET',
            null,
            'Unable to request verification'
        );
    },
    respondToInviteSuggestion: (organisationId: string, contactId: string, rejected: boolean) => {
        return afetch.request<InviteSuggestionDto>(
            apiUrls.respondToInviteSuggestion(),
            'PUT',
            {
                organisationId: organisationId,
                contactId: contactId,
                rejected: rejected
            },
            'Unable to set team members',
            undefined,
            true
        );
    },
    setTeamMembers(teamId: number, payload: SetOrganisationTeamMembersRequest) {
        return afetch.request<SetOrganisationTeamMembersRequest>(
            apiUrls.setTeamMembers(teamId),
            'POST',
            payload,
            'Unable to set team members',
            undefined,
            true
        );
    },
    inviteMember(email: string) {
        return afetch.request<null, string>(
            apiUrls.inviteMember(email),
            'GET',
            null,
            'Unable to invite colleagues'
        );
    },
    getSuggestionsByEmail(email: string) {
        return afetch.request<null, UserOrgSuggestionDTO>(
            apiUrls.getSuggestionsByEmail(email),
            'GET',
            null,
            'Unable to get suggestions'
        );
    },
    getUsers(id: OrganisationDTO['id'], term: string) {
        const params = new URLSearchParams();
        if (term) params.append('term', term);
        const url = `${apiUrls.getUsers(id)}?${params.toString()}`;
        return afetch.request<null, { data: OrganisationUser[] } | null>(
            url,
            'GET',
            null,
            'Unable to get organizations users'
        );
    },
    searchInternalByName(name: string) {
        return afetch.request<null, MergedOrganisationDTO[]>(
            `${AppConfig.Settings.api.externalUri}/organisations/search/${name}/internal?getAdminDetails=true`,
            'GET',
            null,
            'Unable to get organizations'
        );
    }
};

export default apiCalls;
