/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import history from '../../history';
import '../../css/People.css';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
import OrganisationSyncApi from '../../api/organisationsync/OrganisationSync';
import { OrganisationSyncItemType } from '../../api/organisationsync/ResponseTypes';

import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';

import { bulkPut, getAll } from 'src/db/SyncCache';
import Utilities from 'src/Utilities';
import { UserQualification } from 'src/api/contacts/ResponseTypes';
import ProfileApi from 'src/api/profile/Profile';
import { ContactAvailabilityState } from 'src/store/Availability';
import { deleteValue, getValue, setValue } from 'src/db/KeyValueCache';
import { Badge, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import ContactProfileModal from '../../pages/ContactProfile';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import TimePresetSlider from '../availability/TimePresetSlider';
import theme from 'src/css/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PeopleFilter from './PeopleFilter';
import PeopleGrid from './PeopleGrid';
import Joyride from 'react-joyride';
import InternalTracker from 'src/InternalTracker';
import { ChatBotAvailabilityFilters, MatchableEntities } from 'src/api/chatbot/RequestTypes';

export interface OrganisationSyncNormalizedItem {
    key: string;
    value: OrganisationSyncUserProfile | OrganisationSyncUserAvailability | OrganisationSyncOrgTimePreset | OrganisationSyncOrgTeamWithMembers | OrganisationSyncOrgLocation | OrganisationSyncOrgGroupWithMembers | OrganisationAllSharingRelationships
    entityId: string;
    entityType: OrganisationSyncItemType
} 

export interface WorkerAttribute {
    id: number;
    userId: string;
    sectorId: number;
    skillId: number;
    subSectorId: number;
    sectorName: string;
    skillName: string;
    subSectorName: string;
}

export interface WorkerVerificationDetail {
    id: number;
    typeId: VerificationTypeId;
    verifyingContactId: string;
    verifiedContactId: string;
    start: string;
    end: string;
    startedAt: string;
    endedAt: string;
    contactName: string;
    organisationId: string;
    organisationName: string;
    contactEmail: string;
    endedByContactId: string;
    endedByUserId: string;
    endedByUserName: string;
    // verificationTrustId: VerificationTrust;
    verifyingUserName: string;
    verifyingUserId: string;
    verifiedUserName: string;
    verifiedUserId: string;
    verifyingOrgName: string;
    verifyingOrgId: string;
}

export interface OrganisationSyncUserProfile {
    id: string;
    contactId: string;
    firstName: string;
    lastName: string;
    headline: string;
    maxDistance: number;
    reported: boolean;
    sMSNotifications: boolean;
    availabilityLastConfirmed: string;
    lastTimelineUpdateAt: string;
    verified: boolean;
    latitude: number;
    longitude: number;
    qualifications: UserQualification[];
    attributes: WorkerAttribute[];
    verifications: WorkerVerificationDetail[];
    ratings: WorkerRating[];

    lastUpdatedTs?: number;
    totalRatings?: number;
    avgRating?: number;
    ownRating?: WorkerRating;
    milesDistance?: number;
    totalAvailableMinutesForSelectedDays?: number;
}

export interface WorkerRating {
    id: number;
    raterUserId: string;
    raterFirstName: string;
    raterLastName: string;
    raterOrganisationName: string;
    raterOrganisationId: string;
    udatedAt: string;
    stars: number;
    publicComment: string;
    privateComment: string;
    // RaterExternalId\":null,\"RaterExternalOrgId\":null,\"RaterExternalOrgName\":null,\"RaterExternalOrgDomain\":null,\"RaterExternalContactName
}

interface MergedUserProfile extends OrganisationSyncUserProfile {
    totalAvailableMinutesPerDay: {
        [key: string]: number
    },
    compiledAvailability: {
        [key: string]: MiniAvailabilityGridDaySlot[] // Array<number[]>
    }
}

export interface MiniAvailabilityGridDaySlot {
    available: boolean;
    top: number; // pixel
    height: number; // pixel
}

export interface MergedUserProfileState {
    [key: string]: MergedUserProfile
}

interface RepresentingAgency {
    id: string;
    name: string;
}

export interface CachedUserRepresentingAgencies extends RepresentingAgency {
    userIds: string[];
}

export interface CachedOrgLocationToUserDistances {
    locationId: string;
    latitude: number;
    longitude: number;
    users: {
        id: string;
        milesDistance: number;
        latitude: number;
        longitude: number;
    }[]
}

export interface Props {
    onNavigate?: () => void;
    onNotFound?: () => void;
    predefinedFilters?: ChatBotAvailabilityFilters;
}

export interface Preset {
    id?: string;
    name?: string;
    startHour: number;
    endHour: number;
    startMinute: number;
    endMinute: number;
}

export interface SearchParams {
    timePreset: Preset,
    customTimePreset: Preset | null,
    globalSearch?: string;
    teamId: number,
    groupId: number,
    locationId: string,
    attributeIds: string[],
    name: string,
    names: string[], // temporary workaround to allow multiple from chatbot if exists use this over name
    representingAgencies: string[],
    mostAvailbleDates: string[],
    sortType: "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name" // pinned
}

export interface State {
    view: "expanded" | "hovered" | "collapsed";
    userProfiles: MergedUserProfileState;
    searchParams: SearchParams,
    skillsFilter: string;
    representationsFilter: string;
    skillsSearchType: "AND" | "OR";
    representationsSearchType: "AND" | "OR";
    timePresets: Preset[],
    groups: EntitledGroupsWithMembers[],
    locations: CachedLocation[],
    teams: CachedTeamWithMembers[],
    userAttributes: CachedUserAttribute[],
    filterDropdown: 'sort' | 'timepreset' | 'locations' | 'attributes' | 'representations' | null;
    touchScreen: boolean;
    ownUserId: string;
    organisationIsAgency: boolean;
    lastSynced: number;
    syncing: boolean;
    initedRefreshHooks: boolean;
    userRepresentations: CachedUserRepresentingAgencies[];
    openContactId: string;
    scrollBarWidth: number;
    orgToUserLocations: CachedOrgLocationToUserDistances[];
    showFilters?: boolean;
    show?: boolean;
    maxPeopleToDisplay: number;
    expandedDaysToDisplay: number;
    updateFilters: boolean;
    sortedDisplayedUsers: string[];
    joyride: string;
    predefinedFilters?: ChatBotAvailabilityFilters;
    peoplesToDisplayInEmbed: number;
    hasMoreEmbeddedResults: boolean;
    actionSelectorForContactId: string | null;
    actionSelectorForUserId: string | null;
    bulkActionUserIds: string[];
}

interface OrganisationSyncUserAvailability extends Array<number[]> {}

interface OrganisationSyncOrgTimePreset extends Array<CachedTimePreset> {}

export interface CachedTimePreset {
    id: string;
    name: string;
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
    userId: string;
}

interface OrganisationSyncOrgTeamWithMembers extends Array<CachedTeamWithMembers> {}

export interface CachedTeamWithMembers {
    id: number;
    name: string;
    userId: string;
}

interface OrganisationSyncOrgLocation extends Array<CachedLocation> {}

export interface CachedLocation {
    id: string;
    locationPlaceId: string;
    locationPlaceName: string;
    locationFriendlyName: string;
    locationFriendlyAddress: string;
    postCode: string;
    isDefault: boolean;
    latitude: number;
    longitude: number;
}

interface OrganisationSyncOrgGroupWithMembers extends Array<CachedGroupWithMembers> {}

export interface CachedGroupWithMembers {
    id: number;
    name: string;
    teamId: number;
    workerUserId: string;
}

export interface EntitledGroupsWithMembers {
    teamId: number;
    id: number;
    name: string;
    userIds: string[];
}

interface OrganisationAllSharingRelationships extends Array<CachedOrganisationSharingRelationship> {}

export interface CachedOrganisationSharingRelationship {
    workerUserId: string;
    hirerUserId: string;
    delete: boolean;
}

// interface CachedUserAttributes extends Array<CachedSector> {}

// interface CachedSkill {
//     id: number;
//     name: string;
//     userIds: string[];
// }

// interface CachedSubSector {
//     id: number;
//     name: string;
//     skills: CachedSkill[];
// }

// interface CachedSector {
//     id: number;
//     name: string;
//     subSectors: CachedSubSector[];
// }

export interface CachedUserAttribute {
    sectorId: number;
    sectorName: string;
    subSectorId: number;
    subSectorName: string;
    skillId: number;
    skillName: string;
    userIds: string[];
}

const INITIAL_PEOPLE_TO_DISPLAY = 50;

class People extends React.Component<
    Props,
    State
> {
    datesRef: React.RefObject<HTMLDivElement> = React.createRef();
    peopleRef: React.RefObject<HTMLDivElement> = React.createRef();
    scrollCheckDisabled: boolean = false;
    inputDebounce: any = null;
    notFoundCallback: any = null;
    startTime: number = 0;
    state = {
        view: "collapsed" as "expanded" | "hovered" | "collapsed",
        userProfiles: {} as MergedUserProfileState,
        searchParams: {
            timePreset: {
                startHour: 8,
                endHour: 17,
                startMinute: 0,
                endMinute: 0,
                name: "All Day"
            } as Preset,
            globalSearch: "",
            teamId: 1,
            groupId: 1,
            locationId: "",
            attributeIds: [] as string[],
            name: "",
            representingAgencies: [] as string[],
            mostAvailbleDates: [] as string[],
            sortType: "last-updated" as "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name",
            customTimePreset: null as Preset | null,
            names: [] as string[]
        },
        timePresets: [] as CachedTimePreset[],
        groups: [] as EntitledGroupsWithMembers[],
        locations: [] as CachedLocation[],
        teams: [] as CachedTeamWithMembers[],
        userAttributes: [] as CachedUserAttribute[],
        filterDropdown: null,
        expandedDaysToDisplay: 30, // maybe start at 30 increase by 30, up to 90
        // if devices has touch screen
        touchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
        skillsFilter: "",
        skillsSearchType: "AND" as "AND" | "OR",
        ownUserId: "",
        lastSynced: 0,
        syncing: false,
        initedRefreshHooks: false,
        userRepresentations: [] as CachedUserRepresentingAgencies[],
        representationsFilter: "",
        representationsSearchType: "AND" as "AND" | "OR",
        openContactId: "",
        scrollBarWidth: 0,
        organisationIsAgency: false,
        orgToUserLocations: [] as CachedOrgLocationToUserDistances[],
        showFilters: true,
        show: false,
        maxPeopleToDisplay: INITIAL_PEOPLE_TO_DISPLAY,
        updateFilters: false,
        sortedDisplayedUsers: [] as string[],
        joyride: "",
        peoplesToDisplayInEmbed: 10,
        hasMoreEmbeddedResults: true,
        actionSelectorForContactId: null,
        actionSelectorForUserId: null,
        bulkActionUserIds: [] as string[],
    } as State;

    loadPredefinedFilters = async () =>  {
        if (this.props.predefinedFilters) {
            const predefinedFilters = this.props.predefinedFilters;
            
            const userAttributesCache = await getValue("userAttributes");
            const userAttributes = userAttributesCache ? JSON.parse(userAttributesCache) : [] as CachedUserAttribute[];

            const orgDefinedSearchableEntityValues = await getValue("orgDefinedSearchableEntityValues");
            const userRepresentationsCache = await getValue("userRepresentations");
            const userRepresentations = userRepresentationsCache ? JSON.parse(userRepresentationsCache) : [] as CachedUserRepresentingAgencies[];

            let filters = this.state.searchParams;

            // skills
            if (predefinedFilters.skills && predefinedFilters.skills.length) {
                filters.attributeIds = predefinedFilters.skills.map((skill: string) => {
                    const skillName = skill.toLowerCase().trim();
                    const skillMatch = userAttributes.find(attr => attr.skillName.toLowerCase().trim() === skillName);
                    if (skillMatch) {
                        const compoundId = `${skillMatch.sectorId}-${skillMatch.subSectorId}-${skillMatch.skillId}`;
                        return compoundId;
                    }
                    return null;
                }).filter((id: string | null) => id !== null) as string[];
            } else if (predefinedFilters.subSectors && predefinedFilters.subSectors.length) {
                filters.attributeIds = predefinedFilters.subSectors.map((subsector: string) => {
                    const subsectorMatch = userAttributes.find(attr => attr.subSectorName.toLowerCase() === subsector.toLowerCase());
                    if (subsectorMatch) {
                        return `${subsectorMatch.sectorId}-${subsectorMatch.subSectorId}-${subsectorMatch.skillId}`;
                    }
                    return null;
                }).filter((id: string | null) => id !== null) as string[];
            }

            // timepresets
            if (predefinedFilters.preset && orgDefinedSearchableEntityValues && orgDefinedSearchableEntityValues.timePresets) {
                // @ts-ignore
                filters.timePreset = orgDefinedSearchableEntityValues?.timePresets?.find(p => p.name!.toLowerCase() === predefinedFilters!.preset.toLowerCase())
                filters.customTimePreset = null;
            } else if (predefinedFilters.times && predefinedFilters.times.length) {
                // only support for single 
                filters.customTimePreset = predefinedFilters.times.map((time: string) => {
                    // split on : and -
                    const parts = time.split(/:|-/);
                    return {
                        startHour: parseInt(parts[0]),
                        startMinute: parseInt(parts[1]),
                        endHour: parseInt(parts[2]),
                        endMinute: parseInt(parts[3])
                    }
                })[0];
            }

            // agencies
            if (predefinedFilters.agencies && predefinedFilters.agencies.length) {
                filters.representingAgencies = predefinedFilters.agencies.map((agency: string) => {
                    const agencyMatch = userRepresentations.find(a => a.name.toLowerCase() === agency.toLowerCase());
                    if (agencyMatch) {
                        return agencyMatch.id;
                    }
                    return null;
                }).filter((id: string | null) => id !== null) as string[];
            }
            // TODO represenations not showing on grid??

            if (predefinedFilters.group && orgDefinedSearchableEntityValues && orgDefinedSearchableEntityValues.groups) {
                // applying group
                const group = orgDefinedSearchableEntityValues.groups.find(g => g.name.toLowerCase() === (predefinedFilters.group ?? "").toLowerCase());
                if (group) {
                    filters.groupId = group.id;
                    filters.teamId = group.teamId;
                }
            } else if (predefinedFilters.team && orgDefinedSearchableEntityValues && orgDefinedSearchableEntityValues.teams) {
                // applying team
                const team = orgDefinedSearchableEntityValues.teams.find(t => t.name.toLowerCase() === (predefinedFilters.team ?? "").toLowerCase());
                if (team) {
                    filters.teamId = team.id;
                    filters.groupId = team.id; // default everyone group has same id as team
                }
            } else {
                filters.teamId = 1;
                filters.groupId = 1;
            }

            filters.name = "";
            // names 
            if (predefinedFilters.names && predefinedFilters.names.length) {
                // filters.name = predefinedFilters.names[0]; // only allowed one for now
                filters.names = predefinedFilters.names;
            } else {
                filters.names = [];
            }

            if (predefinedFilters.dates && predefinedFilters.dates.length) {
                filters.mostAvailbleDates = predefinedFilters.dates;
            }
    
            // always most available since date is mandatory
            filters.sortType = "most-available"
            filters.globalSearch = "";

            // default preset
            if (!filters.timePreset && !filters.customTimePreset && orgDefinedSearchableEntityValues && orgDefinedSearchableEntityValues.timePresets) {
                filters.timePreset = orgDefinedSearchableEntityValues.timePresets[0];
            }

            // console.log("_____ Applying predefined filters <<<<<<<<,", this.props.predefinedFilters, filters, userRepresentations);

            this.setState({
                predefinedFilters: this.props.predefinedFilters,
                searchParams: filters,
                skillsSearchType: "OR",
                representationsSearchType: "OR",
            }, () => {
                this.updateSortAndFilterDisplayedUsers()
            })
        }
    }

    componentDidMount() {
        if (!localStorage.getItem("FeaturePeopleGrid")) {
            return null;
        }

        
        this.loadPredefinedFilters()

        this.setState({
            lastSynced: localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0,
            scrollBarWidth: Utilities.getScrollbarWidth()
        })
        this.attemptToGetUserId();
    }

    async attemptToGetUserId() {
        const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "{}") : {};
        if (localUser && localUser.id && localUser.organisationId) { 
            // console.log("FOUND all... getting data")
            const lastSynced = localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0;
            if (lastSynced === 0) {
                await new Promise((resolve, reject) => { setTimeout(resolve, 2000) });
            }
            this.setState({
                ownUserId: localUser.id,
                organisationIsAgency: localUser.organisationIsAgency
            }, () => {
                this.reloadFromLocalData(undefined, true);
                this.sync();
            })
        } else {
            // console.log("NOT FOUND all...")
            setTimeout(() => {
                this.attemptToGetUserId();
            }, 1000);
            return;
        }
    }

    async initRefreshHooks() {
        // on window focus sync
        window.addEventListener('focus', () => {
            this.sync();
        });
        (window as any).triggerPeopleGridRefresh = () => {
            setTimeout(() => {
                this.sync();
            }, 1000);
        }
        this.setState({
            initedRefreshHooks: true
        })
    }

    componentWillUpdate() {
        this.startTime = performance.now();
    }

    componentDidUpdate() {
        const endTime = performance.now();
        // console.log('[Main] Render time:', endTime - this.startTime);
    }

    async updateSortAndFilterDisplayedUsers() {
        const groupUserIds = this.state.groups.find(group => group.id === this.state.searchParams.groupId)?.userIds || [];
        const currentOgLocationUserDistances = this.state.orgToUserLocations.find(item => item.locationId === this.state.searchParams.locationId)?.users || [];

        InternalTracker.trackEvent("People Grid Filter", {
            "global": this.state.searchParams.globalSearch,
            "attributes": this.state.searchParams.attributeIds.join(","),
            "name": this.state.searchParams.name,
            "agencies": this.state.searchParams.representingAgencies.join(","),
            "most-available-dates": this.state.searchParams.mostAvailbleDates.join(","),
            "sort-type": this.state.searchParams.sortType,
            "skill-search-type": this.state.skillsSearchType,
            "agencies-search-type": this.state.representationsSearchType,
            "group": this.state.searchParams.groupId,
            "team": this.state.searchParams.teamId,
            "location": this.state.searchParams.locationId,
            "timepreset": this.state.searchParams.timePreset.id,
            "custom-timepreset": this.state.searchParams.customTimePreset ? this.state.searchParams.customTimePreset.id : "",
            "timepreset-start": this.state.searchParams.timePreset.startHour + ":" + this.state.searchParams.timePreset.startMinute,
            "timepreset-end": this.state.searchParams.timePreset.endHour + ":" + this.state.searchParams.timePreset.endMinute,
        })

        console.log("++ Filtering ", this.state.searchParams);

        let orderedAndFilteredUsers = Object.values(this.state.userProfiles).map(user => {
            user.milesDistance = currentOgLocationUserDistances.find(u => u.id === user.id)?.milesDistance ? Math.round(currentOgLocationUserDistances.find(u => u.id === user.id)?.milesDistance || 1000000) : 1000000;
            user.totalAvailableMinutesForSelectedDays = this.state.searchParams.mostAvailbleDates.reduce((acc, date) => acc + (user.totalAvailableMinutesPerDay[date] || 0), 0);
            return user;
        }).sort((a, b) => {
            switch (this.state.searchParams.sortType) {
                case "name":
                    return a.firstName.localeCompare(b.firstName);
                case "last-updated":
                    return (b.lastUpdatedTs || 0) - (a.lastUpdatedTs || 0);
                case "overall-rating":
                    return (b.avgRating || 0) - (a.avgRating || 0);
                case "own-rating":
                    return (b.ownRating ? b.ownRating.stars : 0) - (a.ownRating ? a.ownRating.stars : 0);
                case "distance":
                    return (a.milesDistance || 0) - (b.milesDistance || 0);
                case "most-available":
                    // sort first by totalAvailableMinutesForSelectedDays,
                    // then by last-updated, to avoid returning inactive users
                    return (b.totalAvailableMinutesForSelectedDays || 0) - (a.totalAvailableMinutesForSelectedDays || 0) || (b.lastUpdatedTs || 0) - (a.lastUpdatedTs || 0);
                default:
                    return 0;
            }
        }).map((user) => {
            if (!user || !user.firstName) {
                return null;
            }

            // unique based on oranisationId+typeId (only if both preset)
            // cannot do this, otherwise active, and recently represented collides user.verifications = user.verifications ? user.verifications.filter((v, i, a) => a.findIndex(t => t.organisationId === v.organisationId && t.typeId === v.typeId) === i) : [];
            const loweredKeywords = this.state.searchParams.globalSearch!.toLowerCase();
            const loweredFirstName = user.firstName.toLowerCase();
            const loweredLastName = user.lastName.toLowerCase();

            const invisible = (
                !groupUserIds.includes(user.id) ||
                this.state.searchParams.attributeIds.length > 0 && ( 
                    (this.state.skillsSearchType === "OR" && !this.state.searchParams.attributeIds.some(attrId => user.attributes.find(attr => `${attr.sectorId}-${attr.subSectorId}-${attr.skillId}` === attrId)) ) ||
                    (this.state.skillsSearchType === "AND" && !this.state.searchParams.attributeIds.every(attrId => user.attributes.find(attr => `${attr.sectorId}-${attr.subSectorId}-${attr.skillId}` === attrId)) )
                ) ||
                (
                    this.state.searchParams.name &&
                    !loweredFirstName.includes(this.state.searchParams.name.toLowerCase()) &&
                    !loweredLastName.includes(this.state.searchParams.name.toLowerCase()) && 
                    !(loweredFirstName + " " + loweredLastName).includes(this.state.searchParams.name)
                ) ||
                (
                    this.state.searchParams.names.length > 0 &&
                    !this.state.searchParams.names.some(name => loweredFirstName + " " + loweredLastName === name)
                ) ||
                ( this.state.searchParams.globalSearch &&
                    (
                        !(user.firstName && loweredFirstName.includes(loweredKeywords)) &&
                        !(user.lastName && loweredLastName.includes(loweredKeywords)) &&
                        !(user.headline && user.headline.toLowerCase().includes(loweredKeywords)) &&
                        !user.attributes.find(attr => attr.skillName.toLowerCase().includes(loweredKeywords)) &&
                        !(user.qualifications.find(qual => qual.field.toLowerCase().includes(loweredKeywords) || qual.degree.toLowerCase().includes(loweredKeywords)))
                    )
                ) ||
                (this.state.searchParams.representingAgencies.length > 0 && (
                    (this.state.representationsSearchType === "OR" && !this.state.userRepresentations.filter(rep => this.state.searchParams.representingAgencies.includes(rep.id)).some(rep => rep.userIds.includes(user.id))) ||
                    (this.state.representationsSearchType === "AND" && !this.state.userRepresentations.filter(rep => this.state.searchParams.representingAgencies.includes(rep.id)).every(rep => rep.userIds.includes(user.id)))
                ))
            )

            return invisible ? null : user.id;
        }).filter((user) => user !== null);

        // console.log("New order and filter", orderedAndFilteredUsers);
        this.setState({
            sortedDisplayedUsers: (orderedAndFilteredUsers || []) as string[]
        });

        clearTimeout(this.notFoundCallback);
        this.notFoundCallback = setTimeout(() => {
            console.log("displayed: " + this.state.sortedDisplayedUsers.length)
            if (this.props.predefinedFilters && this.state.sortedDisplayedUsers.length === 0 && this.props.onNotFound) {
                this.props.onNotFound();
            }
        }, 300)

        if (!this.state.show && orderedAndFilteredUsers.length) {
            if (this.props.predefinedFilters === undefined) {
                document.body.classList.add("people-grid-visible");
            }
            this.setState({
                show: true
            })
        }
    }

    async recalculateUserOrgLocations(recalculateForWorkerIds: string[], eraseAndRecalcuateForLocations: CachedLocation[]) {
        // changed an org location, complete recompile
        if (eraseAndRecalcuateForLocations && eraseAndRecalcuateForLocations.length) {
            await deleteValue("orgUserLocations");
            recalculateForWorkerIds = Object.values(this.state.userProfiles).map((user) => user.id);
        }

        const locations = this.state.locations;
        const cacheStr = await getValue("orgUserLocations");
        let cache = (cacheStr ? JSON.parse(cacheStr) : []) as CachedOrgLocationToUserDistances[];
        // console.log("____ LOcation cache init", cache, recalculateForWorkerIds, locations);

        for (let i = 0; i < recalculateForWorkerIds.length; i++) {
            let user = this.state.userProfiles[recalculateForWorkerIds[i]];
            if (!user || !user.latitude || !user.longitude) {
                continue;
            }

            let userLatitude = user.latitude;
            let userLongitude = user.longitude;

            for (let j = 0; j < locations.length; j++) {
                let location = locations[j];
                let locationLatitude = location.latitude;
                let locationLongitude = location.longitude;
                let distance = Utilities.distanceBetweenTwoLatLngCoordinatesInMiles(userLatitude, userLongitude, locationLatitude, locationLongitude);
                let cacheIndex = cache.findIndex(item => item.locationId === location.id);
                if (cacheIndex > -1) {
                    let userIndex = cache[cacheIndex].users.findIndex(item => item.id === user.id);
                    if (userIndex > -1) {
                        cache[cacheIndex].users[userIndex].milesDistance = distance;
                    } else {
                        cache[cacheIndex].users.push({
                            id: user.id,
                            milesDistance: distance,
                            latitude: userLatitude,
                            longitude: userLongitude
                        });
                    }
                } else {
                    cache.push({
                        locationId: location.id,
                        latitude: locationLatitude,
                        longitude: locationLongitude,
                        users: [{
                            id: user.id,
                            milesDistance: distance,
                            latitude: userLatitude,
                            longitude: userLongitude
                        }]
                    })
                }
            }
        }

        await setValue("orgUserLocations", JSON.stringify(cache));
        this.setState({
            orgToUserLocations: cache,
            searchParams: {
                ...this.state.searchParams,
                locationId: cache.length ? cache[0].locationId : ""
            }
        })
    }
    
    async recalculateUserRepresentations(recalculateForWorkerIds: string[]) {
        const cachesStr = await getValue("userRepresentations");
        let cache = (cachesStr ? JSON.parse(cachesStr) : []) as CachedUserRepresentingAgencies[];
    
        for (let userKey in this.state.userProfiles) {
            if (recalculateForWorkerIds.includes(userKey)) {
                let user = this.state.userProfiles[userKey];
                let representations = user.verifications ? user.verifications.filter(verification => verification.typeId === VerificationTypeId.Representation && !verification.endedAt) : [];
                // delete all worker representations from cache
                for (let i = 0; i < representations.length; i++) {
                    let index = cache.findIndex(attr => attr.id === representations[i].organisationId);
                    if (index > -1) {
                        cache[index].userIds = cache[index].userIds.filter(id => id !== user.id);
                        if (cache[index].userIds.length === 0) {
                            cache.splice(index, 1);
                        }
                    }
                }

                // add all worker representations to cache
                for (let i = 0; i < representations.length; i++) {
                    let index = cache.findIndex(attr => attr.id === representations[i].organisationId);
                    if (index > -1) {
                        cache[index].userIds.push(user.id);
                    } else {
                        cache.push({
                            id: representations[i].organisationId,
                            name: representations[i].organisationName,
                            userIds: [user.id]
                        });
                    }
                }
            }
        }

        await setValue("orgAndWorkerDefinedSearchableEntities", JSON.stringify({
            agencies: cache.map(c => c.name.toLowerCase())
        } as MatchableEntities))
        
        if ((window as any).onSearchableEntityChange) {
            (window as any).onSearchableEntityChange(1);
        }

        await setValue("userRepresentations", JSON.stringify(cache));
        this.setState({
            userRepresentations: cache
        })
    }

    async recalculateUserAttributes(recalculateForWorkerIds: string[]) {
        console.log("Recalc attr for ", recalculateForWorkerIds);
        const cacheStr = await getValue("userAttributes");
        let cache = (cacheStr ? JSON.parse(cacheStr) : []) as CachedUserAttribute[];

        for (let userKey in this.state.userProfiles) {
            if (recalculateForWorkerIds.includes(userKey)) {
                let user = this.state.userProfiles[userKey];
                let attributes = user.attributes;
                // delete all worker attributes from cache
                for (let i = 0; i < attributes.length; i++) {
                    let index = cache.findIndex(attr => attr.sectorId === attributes[i].sectorId && attr.subSectorId === attributes[i].subSectorId && attr.skillId === attributes[i].skillId);
                    if (index > -1) {
                        cache[index].userIds = cache[index].userIds.filter(id => id !== user.id);
                        if (cache[index].userIds.length === 0) {
                            cache.splice(index, 1);
                        }
                    }
                }
                
                // add all worker attributes to cache, create sector, subsector, skill if not exists
                for (let i = 0; i < attributes.length; i++) {
                    let index = cache.findIndex(attr => attr.sectorId === attributes[i].sectorId && attr.subSectorId === attributes[i].subSectorId && attr.skillId === attributes[i].skillId);
                    if (index > -1) {
                        cache[index].userIds.push(user.id);
                    } else {
                        cache.push({
                            sectorId: attributes[i].sectorId,
                            sectorName: attributes[i].sectorName,
                            subSectorId: attributes[i].subSectorId,
                            subSectorName: attributes[i].subSectorName,
                            skillId: attributes[i].skillId,
                            skillName: attributes[i].skillName,
                            userIds: [user.id]
                        });
                    }
                }
            }
        }

        await setValue("workerDefinedSearchableEntities", JSON.stringify({
            names: Object.values(this.state.userProfiles).map(user => user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()),
            sectors: [...new Set(cache.filter(c => c.sectorName).map(c => c.sectorName.toLowerCase()))],
            subsectors: [...new Set(cache.filter(c => c.subSectorName).map(c => c.subSectorName.toLowerCase()))],
            skills: [...new Set(cache.filter(c => c.skillName).map(c => c.skillName.toLowerCase()))],
        } as MatchableEntities))

        if ((window as any).onSearchableEntityChange) {
            (window as any).onSearchableEntityChange(2);
        }

        await setValue("userAttributes", JSON.stringify(cache)); // todo needs to adjust when group changes?
        const reduceToAtLeast5InstanceSkills = false //  cache.length > 300;
        this.setState({
            userAttributes: reduceToAtLeast5InstanceSkills ? cache.filter(a => a.userIds.length > 5) : cache // todo lazy load
        })
    }

    clearFilters() {
        InternalTracker.trackEvent("Cleared Filters");
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                globalSearch: "",
                attributeIds: [],
                name: "",
                representingAgencies: [],
                sortType: "last-updated",
            },
            updateFilters: true
        }, () => {
            this.reloadFromLocalData();
            this.scrollToTop();
            this.setState({
                updateFilters: false
            }, () => {
                this.updateSortAndFilterDisplayedUsers();
            })
        })
    }

    async reloadFromLocalData(newUserIds?: string[], firstLoad?: boolean) {
        // this.scrollCheckDisabled = true;
        return new Promise<void>( async (resolve, reject) => {
            const data = await getAll();
            let mergedData: MergedUserProfileState = {};
            const profiles = data.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserProfile);
            const availabilities = data.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserAvailability);
            const timepresets = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.TimePresetList);
            const teams = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.TeamWithMembersList);
            const locations = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.LocationList);
            const groups = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.GroupWithMembersList);
            const allOrgAvailabilityShares = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.OrganisationReceivingFrom);
            const workersNoLongerSharing = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => item.delete).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];
            const workersStillSharingWithMe = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => !item.delete && item.hirerUserId === this.state.ownUserId).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];
            const workersStillsharingWithMyOrg = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => !item.delete).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];

            // TODO remove no-longer sharing

            if (timepresets || teams || locations || groups) {
                let allTeams: CachedTeamWithMembers[] = teams ? teams.value : [];
                allTeams.unshift({ id: 0, name: "Just Me", userId: "" })
                allTeams.unshift({ id: 1, name: "Everyone", userId: "" })

                let allEntitledGroups = groups ? groups.value.filter(group => teams.value.map(t => t.id).includes(group.teamId)) : [];
                let allEntitledGroupList: EntitledGroupsWithMembers[] = [...new Set(allEntitledGroups.map(group => group.id))].map(id => {
                    const allTeamIds = [...new Set(groups.value.filter(g => g.id === id).map(g => g.teamId))]
                    return allTeamIds.map(teamId => {
                        return {
                            id: id,
                            name: allEntitledGroups.find(g => g.id === id)!.name,
                            userIds: groups.value.filter(g => g.id === id).map(g => g.workerUserId),
                            teamId: teamId
                        } as EntitledGroupsWithMembers
                    })
                }).flat();

                for (let i = 0; i < allTeams.length; i++) {
                    allEntitledGroupList.push({
                        id: allTeams[i].id,
                        name:  allTeams[i].id === 1 ? "Everyone sharing with my organisation" : allTeams[i].id === 0 ? "Everyone sharing with me" : ("Everyone sharing with " + allTeams[i].name),
                        userIds: (allTeams[i].id === 0) ? workersStillSharingWithMe : (allTeams[i].id === 1) ? workersStillsharingWithMyOrg : workersStillsharingWithMyOrg,
                        teamId: allTeams[i].id
                    })
                }

                this.setState({
                    timePresets: timepresets ? timepresets.value : [],
                    teams: allTeams,
                    locations: locations ? locations.value : [],
                    groups: allEntitledGroupList,
                })

                await setValue("orgDefinedSearchableEntityValues", {
                    timePresets: timepresets ? timepresets.value : [],
                    teams: allTeams,
                    locations: locations ? locations.value : [],
                    groups: allEntitledGroupList,
                })

                await setValue("orgDefinedSearchableEntities", JSON.stringify({
                    presets: timepresets ? timepresets.value.map(v => v.name.toLowerCase()) : [],
                    teams: allTeams.map(t => t.name.toLowerCase()),
                    groups: allEntitledGroupList.map(g => g.name.toLowerCase()).filter(g => !g.startsWith("everyone sharing with"))
                } as MatchableEntities))

                if ((window as any).onSearchableEntityChange) {
                    (window as any).onSearchableEntityChange(3);
                }

                if (firstLoad) {
                    // console.log("STATES__", timepresets.value[0])

                    if (!this.props.predefinedFilters) {
                        this.setState({
                            showFilters: false,
                            searchParams: {
                                ...this.state.searchParams,
                                // teamId: allTeams && allTeams[0] ? allTeams[0].id : 0, // todo this breaks
                                // groupId: allEntitledGroupList && allEntitledGroupList[0].id ? allEntitledGroupList[0].id : 0,
                                timePreset: (timepresets && timepresets.value) ? timepresets.value[0] : (this.state.timePresets[0] || 0)
                            }
                        }, () => {
                            this.setState({
                                showFilters: true
                            })
                        })
                    }
                }
            }

            for (let i = 0; i < profiles.length; i++) {
                let availability = availabilities.find((item: OrganisationSyncNormalizedItem) => item.entityId === profiles[i].entityId)?.value;
                // construct availability based on timepresets for next 90 days in given timeprset to be rendered top to down in 50 height x 20 width grid
                let now = new Date();
                let todayAvailableRanges: Array<number[]> = [];
                let until = Utilities.dateAdd(new Date(), "day", this.state.expandedDaysToDisplay);
                let compiledAvailability: MiniAvailabilityGridDaySlot[] = [];
                let totalAvailableMinutesPerDay: { [key: string]: number } = {};

                if (availability === undefined) {
                    console.log("No availability found for " + profiles[i].entityId);
                    continue;
                }
                

                let timePresetToUse = this.state.searchParams.customTimePreset || this.state.searchParams.timePreset;
                // console.log("Preset: " + timePresetToUse.startHour + ":" + timePresetToUse.startMinute + " - " + timePresetToUse.endHour + ":" + timePresetToUse.endMinute);

                while (now < until) {
                    const dateHash = Utilities.formatDate(now, "YYYY-MM-DD");
                    todayAvailableRanges = [];
                    const dayStartTs = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), timePresetToUse.startHour, timePresetToUse.startMinute).getTime() / 1000);
                    const dayEndTs = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), timePresetToUse.endHour, timePresetToUse.endMinute).getTime() / 1000);
                    const inclusiveRanges = availability.filter(availableRange => (availableRange[0] <= dayEndTs && availableRange[0] >= dayStartTs) || (availableRange[1] <= dayEndTs && availableRange[1] >= dayStartTs) || (availableRange[0] <= dayStartTs && availableRange[1] >= dayEndTs));
                    for (let i = 0; i < inclusiveRanges.length; i++) {
                        let start = inclusiveRanges[i][0] < dayStartTs ? dayStartTs : inclusiveRanges[i][0];
                        let end = inclusiveRanges[i][1] > dayEndTs ? dayEndTs : inclusiveRanges[i][1];
                        todayAvailableRanges.push([start, end]);
                    }

                    let adjustedDaySlot: MiniAvailabilityGridDaySlot[] = [];
                    const DAY_SLOT_HEIGHT = 50;
                    const MINUTES_PER_PIXEL = (timePresetToUse.endHour - timePresetToUse.startHour) * 60 / DAY_SLOT_HEIGHT;
                    
                    // create today's avaialble and unavailable ranges, in order to render them in grid that is 50px height, 8am is 0 pixel, 5pm is 50 pixel
                    for (let i = 0; i < todayAvailableRanges.length; i++) {
                        const start = todayAvailableRanges[i][0];
                        const end = todayAvailableRanges[i][1];
                        const startPixel = Math.round(Math.round((start - dayStartTs) / MINUTES_PER_PIXEL) / 60);
                        const endPixel = Math.round(Math.round((end - dayStartTs) / MINUTES_PER_PIXEL) / 60);
                        adjustedDaySlot.push({
                            available: true,
                            top: startPixel,
                            height: endPixel - startPixel
                        });
                    }
                    
                    compiledAvailability[dateHash] = adjustedDaySlot;
                    totalAvailableMinutesPerDay[dateHash] = todayAvailableRanges.reduce((acc, range) => acc + (range[1] - range[0]) / 60, 0);
                    now = Utilities.dateAdd(now, "day", 1);
                }

                mergedData[profiles[i].entityId] = {
                    ...profiles[i].value,
                    compiledAvailability: compiledAvailability,
                    totalAvailableMinutesPerDay: totalAvailableMinutesPerDay
                }
            }

            this.setState({ 
                userProfiles: mergedData,
                updateFilters: false
            }, () => {

                // if (newUserIds?.length || firstLoad) {
                    this.recalculateUserAttributes(newUserIds || []);
                    this.recalculateUserRepresentations(newUserIds || []);
                    this.recalculateUserOrgLocations(newUserIds || [], locations ? locations.value : []);
                    this.updateSortAndFilterDisplayedUsers() // todo don't we have to wait for above state changes?
                //}

                if (!localStorage.getItem("PeopleGridJoyRideSkipped")) { 
                    setTimeout(() => {
                        this.setState({
                            joyride: "people"
                        })
                    }, 1000)
                }

                resolve();
            });
        });
    }

    scrollToTop() {
        const wrapperEl = document.getElementById("people-wrapper");
        // console.log("SCROLL TO TOP" + wrapperEl?.scrollTop);
        if (wrapperEl) {
            wrapperEl.scrollTop = 0;
            // console.log("SCROLLEd TO TOP" + wrapperEl?.scrollTop);
        }
    }

    async sync() {
        InternalTracker.trackEvent("People Grid Syncing");

        this.setState({
            syncing: true
        })
        const lastSynced = localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0;
        const updates = await OrganisationSyncApi.sync(lastSynced);
        let normalized: OrganisationSyncNormalizedItem[] = [];
        for (let i = 0; i < updates.updates.length; i++) {
            const parsedValue = JSON.parse(updates.updates[i].value);
            const normalizedEntry: any = Utilities.pascalCaseToCamelCaseRecursive(parsedValue)

            // console.log("___ normalizedEntry", normalizedEntry);

            if (normalizedEntry && normalizedEntry.lastTimelineUpdateAt) { // user profile fill out cached
                normalizedEntry.totalRatings = normalizedEntry.ratings ? normalizedEntry.ratings.length : 0;
                normalizedEntry.avgRating = normalizedEntry.totalRatings ? Math.round(normalizedEntry.totalRatings > 0 ? normalizedEntry.ratings.reduce((acc, r) => acc + r.stars, 0) / normalizedEntry.totalRatings : 0) * 10 / 10 : 0;
                normalizedEntry.ownRating = normalizedEntry.totalRatings ? normalizedEntry.ratings.find(r => r.raterUserId === this.state.ownUserId) || undefined : undefined;
                const lastTimelineUpdatedAtTs = normalizedEntry.lastTimelineUpdateAt ? new Date(normalizedEntry.lastTimelineUpdateAt).getTime() : 0;
                const lastAvailabilityCofirmedTs = normalizedEntry.availabilityLastConfirmed ? new Date(normalizedEntry.availabilityLastConfirmed).getTime() : 0;
                normalizedEntry.lastUpdatedTs = Math.max(lastTimelineUpdatedAtTs, lastAvailabilityCofirmedTs) || 0;
            }

            normalized.push({
                ...updates.updates[i],
                value: normalizedEntry
            })
        }
        let newUserIds = normalized.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserProfile).map((item: OrganisationSyncNormalizedItem) => item.entityId);
        console.log("___ updates", normalized);

        await bulkPut(normalized).then( async () => {
            localStorage.setItem('lastSynced', updates.lastSynced.toString());
            await this.reloadFromLocalData(newUserIds);
            this.setState({
                lastSynced: updates.lastSynced,
                syncing: false
            })
            if (!this.state.initedRefreshHooks) {
                this.initRefreshHooks();
            }
        }).catch((e) => {
            this.setState({
                syncing: false
            })
            // console.log("___ failed to bulk put");
        })
        
    }

    public render() {
        const numberOfAvailabilityDays = this.state.view === "expanded" || this.state.predefinedFilters !== undefined ? this.state.expandedDaysToDisplay : this.state.view === "hovered" ? 6 : 0;
        const startDay = new Date();
        const dayHashRange = [...Array(numberOfAvailabilityDays).keys()].map((i) => {
            return Utilities.formatDate(Utilities.dateAdd(startDay, "day", i), "YYYY-MM-DD");
        });

        if (!this.state.show) {
            return null;
        }

        let peopleRendered = 0;
        // console.log("People render started()");

        return (
            <aside 
                className="people-wrapper" 
                data-predefined={this.state.predefinedFilters !== undefined ? "true" : "false"}
                data-view={this.state.predefinedFilters !== undefined ? "expanded" : this.state.view}
                onMouseEnter={() => {
                    if (this.state.view === "collapsed" && !this.state.touchScreen && !this.state.joyride && !this.state.predefinedFilters) {
                        this.setState({ view: "hovered" });
                        InternalTracker.trackEvent("People Grid View Changed", {
                            "view": "hovered"
                        });
                    }
                }}
                onMouseLeave={() => {
                    if (this.state.view === "hovered" && !this.state.touchScreen && !this.state.predefinedFilters) {
                        this.setState({ 
                            view: "collapsed",
                            filterDropdown: null,
                            maxPeopleToDisplay: INITIAL_PEOPLE_TO_DISPLAY // todo maybe also reset filters and scroll?
                        }, () => {
                            this.scrollToTop();
                        });
                        InternalTracker.trackEvent("People Grid View Changed", {
                            "view": "collapsed"
                        });
                    }
                }}
                onClick={() => {
                    if (this.state.view === "collapsed" && this.state.touchScreen && !this.state.predefinedFilters) {
                        this.setState({ view: "expanded" });
                        InternalTracker.trackEvent("People Grid View Changed", {
                            "view": "expanded"
                        });
                    }
                }}
                style={{
                    width: this.state.predefinedFilters !== undefined ? '100%' : this.state.view === "collapsed" ? 60 : this.state.view === "hovered" ? (320 + this.state.scrollBarWidth) : "100%"
                }}
                onScroll={(e) => {
                    // @ts-ignore
                    const scrollPosition = e.target.scrollTop;
                    // @ts-ignore
                    const scrollHeight = e.target.scrollHeight - e.target.clientHeight;
                    const screenHeight = window.innerHeight;
                    this.scrollCheckDisabled = true;

                    // console.log("Scroll: " + scrollPosition + " " + screenHeight + " " + scrollHeight);
                    
                    if (scrollPosition > 0 && scrollPosition + screenHeight >= scrollHeight) {
                        console.log("Extend: "  + (this.state.maxPeopleToDisplay + 100));

                        this.setState({
                            maxPeopleToDisplay: this.state.maxPeopleToDisplay + 100
                        }, () => {
                            this.scrollCheckDisabled = false;
                        })
                    }
                }}
            >

                { this.state.showFilters &&
                    <PeopleFilter 
                        {...this.state}
                        setSkillsFilter={(filter) => {
                            this.setState({
                                skillsFilter: filter
                            })
                        }}
                        setSkillsSearchType={(type) => {
                            this.setState({
                                skillsSearchType: type
                            })
                        }}
                        openFilterDropdown={(dropdown) => {
                            this.setState({
                                filterDropdown: dropdown
                            })
                        }}
                        updateFilters={this.state.updateFilters}
                        filter={(searchParams) => {
                            this.setState({
                                searchParams: searchParams,
                                maxPeopleToDisplay: INITIAL_PEOPLE_TO_DISPLAY,
                                updateFilters: true
                            }, () => {
                                this.reloadFromLocalData();
                                this.scrollToTop();
                            })
                        }}
                        setView={(view) => {
                            if (!this.props.predefinedFilters) {
                                InternalTracker.trackEvent("People Grid View Changed", {
                                    "view": view
                                });
                                if (view === "collapsed") {
                                    this.setState({ 
                                        view: "collapsed",
                                        filterDropdown: null,
                                        maxPeopleToDisplay: INITIAL_PEOPLE_TO_DISPLAY // todo maybe also reset filters and scroll?
                                    }, () => {
                                        this.scrollToTop();
                                    });
                                }
                                this.setState({
                                    view: view
                                })
                            }
                        }}
                        setRepresentationsFilter={(filter) => {
                            this.setState({
                                representationsFilter: filter
                            })
                        }}
                        setRepresentationsSearchType={(type) => {
                            this.setState({
                                representationsSearchType: type
                            })
                        }}
                        onDayCountChange={(count) => {
                            this.setState({
                                expandedDaysToDisplay: count
                            }, () => {
                                this.reloadFromLocalData();
                            })
                        }}
                    />
                }

                <PeopleGrid 
                    {...this.state}
                    sync={this.sync.bind(this)}
                    clearFilters={this.clearFilters.bind(this)}
                    openContact={(contactId, userId) => {
                        if (!this.props.predefinedFilters) {
                            if (this.state.view !== "expanded") {
                                this.setState({
                                    view: "expanded"
                                })
                                InternalTracker.trackEvent("People Grid View Changed", {
                                    "view": "expanded"
                                });
                            } else {
                                this.setState({
                                    openContactId: contactId
                                })
                            }
                        } else {
                            this.setState({
                                actionSelectorForContactId: contactId,
                                actionSelectorForUserId: userId
                            })
                        }
                    }}
                    updateFilters={this.state.updateFilters}
                    filter={(searchParams) => {
                        let existingSearchParams = this.state.searchParams;
                        existingSearchParams.mostAvailbleDates = searchParams.mostAvailbleDates;
                        existingSearchParams.sortType = searchParams.sortType;
                        this.setState({
                            searchParams: existingSearchParams,
                            maxPeopleToDisplay: INITIAL_PEOPLE_TO_DISPLAY,
                            updateFilters: true
                        }, () => {
                            this.reloadFromLocalData();
                            this.scrollToTop();
                        })
                    }}
                    sortedDisplayedUsers={this.state.sortedDisplayedUsers.slice(0, this.state.maxPeopleToDisplay)}
                />

                { (this.state.actionSelectorForContactId) &&
                    <div className="inline-modal" >
                        <div className='backdrop' onClick={() => {
                            this.setState({
                                actionSelectorForContactId: ""
                            })
                        }}></div>
                        <div className='content'>
                            <button onClick={() => {
                                this.setState({
                                    bulkActionUserIds: this.state.bulkActionUserIds.includes(this.state.actionSelectorForUserId!) ? this.state.bulkActionUserIds.filter(id => id !== this.state.actionSelectorForUserId) : [...this.state.bulkActionUserIds, this.state.actionSelectorForUserId!],
                                    actionSelectorForContactId: ""
                                })
                            }}>
                                {this.state.bulkActionUserIds.includes(this.state.actionSelectorForUserId!) ? "De-select for Bulk Action" : "Select for Bulk Action"}
                            </button>
                            <button onClick={() => {
                                this.setState({
                                    openContactId: this.state.actionSelectorForContactId!,
                                    actionSelectorForContactId: ""
                                })
                            }}>View Profile</button>
                            <button onClick={() => {
                                window.open("/offers#create/worker/" + this.state.actionSelectorForUserId, "_blank");
                                this.setState({
                                    actionSelectorForContactId: ""
                                })
                            }}>Send Offer</button>
                            <button onClick={() => {
                                this.setState({
                                    actionSelectorForContactId: ""
                                })
                            }}>Close</button>
                        </div>
                    </div>
                }

                { (this.state.openContactId) &&
                    <ContactProfileModal
                        // @ts-ignore
                        contactId={this.state.openContactId}
                        onClosed={() => {
                            this.setState({
                                openContactId: ""
                            })
                        }}
                    />
                }
                { (this.state.joyride !== "") &&
                    <Joyride
                        run={this.state.joyride !== ""}
                        callback={(data) => {
                            if (data.action === "reset" || data.action === "close") {
                                this.setState({
                                    joyride: ""
                                })
                                // TODO save state and setting
                                localStorage.setItem("PeopleGridJoyRideSkipped", "true");
                                // SettingsAPI.update(Setting.Availability_HideGuide, "true") 
                            }
                        }}
                        locale={{
                            last: "Finish"
                        }}
                        continuous={true}
                        steps={[
                            {
                                target: '.people-wrapper .filters button:first-child',
                                content: <div>
                                    <h1>View Availability Faster</h1>
                                    <p>Hover or Tap this new sidebar to see your contacts' available from any page with better search filters, and instant loading</p>
                                </div>,
                                disableBeacon: true
                            },
                        ]}
                    />
                }
                { (this.state.bulkActionUserIds.length) ?
                    <div className='bulk-actions'>
                        <button onClick={() => {
                            window.open("/offers#create/worker/" + this.state.bulkActionUserIds.join(","), "_blank");
                            this.setState({
                                actionSelectorForContactId: ""
                            })
                        }}>Send Offer to {this.state.bulkActionUserIds.length} Contacts</button>
                    </div>
                : null }
            </aside>
        );
    }
}

export default People;