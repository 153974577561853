/* eslint-disable */
import React, { useEffect } from 'react';
import moment from 'moment';
import { Table, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Device, { Resolutions } from '../../css/device';
import { LocationsMarketing } from './Marketing';
import { Spinner } from '../ui-components/Spinner';
import { getFirstError } from '../../api/AuthenticatedFetch';
import LocationsAPI from '../../api/locations/Locations';
import { Feature } from '../../constants';
import { Location, LocationsResponse } from '../../api/locations/ResponseTypes';
import { Content, Empty } from './Teams';
import LocationPicker from '../profile/LocationPicker';
import LocationMap from '../ui-components/LocationMap';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import Hyperlink from '../ui-components/Hyperlink';
import LocationsSubscription from './LocationsSubscription';
import { Star } from '../ui-components/StarRating';
import useSocket from '../util/useSocket';
import PremiumBanner from './PremiumBanner';
import { Organisation } from '../../pages/Organisation';
import '../../css/Organisations.css'
import theme from '../../css/theme';
import InternalTracker from 'src/InternalTracker';
import { ErrorResponse } from 'src/api/BaseResponseTypes';
import { getValue } from 'src/db/KeyValueCache';

const Locations = ({
    isAdmin,
    navigateToSubscriptions,
    setTab,
    hintedElement,
    bringToSubscriptions,
    onCreate
}: {
    isAdmin: boolean;
    navigateToSubscriptions: Function;
    bringToSubscriptions?: Function;
    setTab: Organisation['setTab'];
    hintedElement?: string | null;
    onCreate?: Function;
}) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = React.useState<
        LocationsResponse['data'] | null
    >(null);

    const [isAddingLocation, setIsAddingLocation] = React.useState(false);

    const [selectedLocationId, setSelectedLocationId] = React.useState<
        Location['id'] | null
    >(null);

    const [editLocationName, setEditLocationName] = React.useState('');
    const [showAll, setShowAll] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState<Location | null | undefined>(null);

    useEffect(() => {
        const newLocation = response && response.locations.find(({ id }) => id === selectedLocationId) ? response.locations.find(({ id }) => id === selectedLocationId) : null;
        console.log("ID changedl: " + selectedLocationId, newLocation, newLocation?.locationFriendlyName)
        setSelectedLocation(newLocation);
        if (newLocation) {
            setEditLocationName(newLocation ? newLocation.locationFriendlyName : '');
        }
    }, [selectedLocationId])

    const requestLocations = async () => {

        const staleLocations = await getValue("locations");
        if (staleLocations) {
            setResponse(staleLocations.data);
            setIsLoading(false);
        }

        LocationsAPI.getAll()
            .then(({ data }) => {
                setResponse(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const createNewLocation = async (
        locationPlaceId,
        locationPlaceName,
        locationPlaceComponents,
        locationFriendlyName,
        locationFriendlyAddress
    ) => {
        try {
            setIsLoading(true);
            await LocationsAPI.create({
                locationPlaceId,
                locationPlaceName,
                locationFriendlyName,
                locationPlaceComponents,
                locationFriendlyAddress
            });
            requestLocations();
            if (onCreate)
                onCreate();
            toast.success('Location created!');
        } catch (error) {
            setIsLoading(false);
        }
    };

    const editLocation = async () => {
        InternalTracker.trackEvent("", {
            category: 'Locations',
            action: 'Location Updated'
        });

        const id = selectedLocation?.id;
        if (id == null) return;
        setIsLoading(true);
        await LocationsAPI.edit({ id, locationFriendlyName: editLocationName });
        setSelectedLocationId(null);
        toast.success('Location updated!');
        requestLocations();
    };

    const deleteLocation = async (id: string) => {
        if (id == null) return;
        try {
            setIsLoading(true);
            await LocationsAPI.delete(id);
            toast.success('Location deleted!');
            setSelectedLocationId(null);
            requestLocations();
        } catch (error) {
            toast.error(getFirstError(error as ErrorResponse) || 'Unable to delete location');
            setIsLoading(false);
        }
    };

    const capacity = (response && response.additionalLocationsCapacity) || 0;

    const capacityUsed = (response && response.additionalLocationsUsed) || 0;

    const isCapacityFull = capacityUsed >= capacity;

    React.useEffect(() => {
        requestLocations();
    }, []);

    /**
     * TODO: Prevent duplicate requests here as we will get one from SignalR
     * in addition to refreshing the list after create/delete
     * create/delete could instead just update state rather than hard refreshing list
     */
    useSocket('subscriptions', 'LocationsModified', requestLocations);

    if (isLoading) return null;

    if (!isLoading && response == null)
        return (
            <>
                <LocationsMarketing />
                <hr />
                <Content>
                    <h3>Your Locations</h3>
                    <div className="layout horizontal center-justified flex">
                        <p>
                            There was an error requesting the locations, please
                            try again.
                        </p>
                    </div>
                </Content>
            </>
        );

    return (
        <section className="main" data-section="locations">
            <div className="section-header">
                <h2>Locations</h2>
                { (!isAdmin) &&
                    <p>Only admins are allowed to edit locations.</p>
                }
                { (isAdmin) &&
                    <p>
                        Add locations for offers for staff at alternative locations.
                        { (capacity !== 0) && `You currently have ${capacityUsed} locations, out of ${capacity}` }
                    </p>
                }
                <div className="options">
                    { (isAdmin && capacity === 0) &&
                        <button 
                            onClick={() => {
                                if (bringToSubscriptions)
                                    bringToSubscriptions();
                            }} 
                            className={hintedElement === "try-locations-btn" ? "pulse no-size" : ""}
                        >
                            Try it for 14 days, completely free
                        </button>
                    }
                    { (isAdmin && capacity !== 0) &&
                        <button
                            onClick={() => {
                                InternalTracker.trackEvent("", {
                                    category: 'Locations',
                                    action: 'Location Add Attempt'
                                });
                                if (isCapacityFull) {
                                    InternalTracker.trackEvent("", {
                                        category: 'Locations',
                                        action: 'Shown Run Out of Locations'
                                    });
                                    alert("You have no more locations slots, please buy a new location subscription to add another location")
                                } else {
                                    setIsAddingLocation(true);
                                }
                            }}
                        >
                            Add New Location
                        </button>
                    }
                    { (isAdmin && capacity !== 0) &&
                        <button onClick={() => {
                            InternalTracker.trackEvent("", {
                                category: 'Locations',
                                action: 'Change Capacity Clicked'
                            });
                            
                            if (bringToSubscriptions)
                                bringToSubscriptions();
                        }}>
                            Change capacity
                        </button>
                    }
                </div>
            </div>
            
            <div 
                className="section-content"
                style={(response?.locations.length === 0) ? { marginTop: -20 } : {}}
            >
                {response!.locations.length !== 0 &&
                    <div className="locations">
                        {response!.locations
                            .sort(sortLocations)
                            .map(
                                ({
                                    isDefault,
                                    locationFriendlyName,
                                    locationPlaceName,
                                    locationFriendlyAddress,
                                    locationPlaceId,
                                    id,
                                    isLocked,
                                    unlockedOn
                                }, i) => {
                                    if (i > 5 && !showAll) return null;
                                    return (
                                        <div
                                            className="card"
                                            key={id}
                                            id={"location-" + id}
                                            onClick={() => {
                                            }}
                                        >
                                            <div className="card-header">
                                                <div style={{
                                                    padding: 0,
                                                    flexBasis: '100%',
                                                    flexWrap: 'wrap',
                                                    height: 'unset',
                                                    paddingLeft: 0,
                                                    width: '100%',
                                                    justifyContent: 'left'
                                                }}>
                                                    <h4>{locationFriendlyName}</h4>
                                                    <p>{locationFriendlyAddress}</p>
                                                </div>
                                            </div>
                                            <div className="card-content">
                                                <LocationMap
                                                    showDirections={false}
                                                    placeId={locationPlaceId}
                                                    explicitHeight={240}
                                                />
                                            </div>
                                            <div className="card-options">
                                                <button
                                                    id={'location-' + id + '-editbtn'}
                                                    onClick={ async () => {
                                                        setSelectedLocationId(id);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (isLocked) {
                                                            alert(`This location has been used in a recent offer. It can be deleted ${moment().to(
                                                                unlockedOn
                                                            )}.`)
                                                        } else {
                                                            InternalTracker.trackEvent("", {
                                                                category: 'Locations',
                                                                action: 'Location Deleted'
                                                            });

                                                            deleteLocation(id);
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                }

                {isAddingLocation && (
                    <LocationPicker
                        show={isAddingLocation}
                        onHide={() => {
                            setIsAddingLocation(false);
                        }}
                        onChange={createNewLocation}
                        placeId={null}
                        allowFriendlyName={true}
                    />
                )}

                {selectedLocationId && selectedLocation && (
                    <Modal
                        onHide={() => {
                            setSelectedLocationId(null);
                        }}
                        show={selectedLocationId !== null}
                    >
                        <Modal.Body>
                            <h4>Edit Location</h4>
                            <div>
                                <label htmlFor="locationFriendlyName">
                                    Extra information
                                </label>
                                <input
                                    className="form-control"
                                    placeholder="E.g. Warehouse 3, Door 8, Floor 4"
                                    value={editLocationName}
                                    onChange={(e) => {
                                        setEditLocationName(e.target.value);
                                    }}
                                />
                            </div>

                            <div
                                className="layout vertical"
                                style={{
                                    height: 200,
                                    width: '100%',
                                    margin: '15px 0 0 0'
                                }}
                            >
                                <LocationMap
                                    placeId={selectedLocation!.locationPlaceId}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="horizontal layout end-justified">
                            <Button
                                onClick={() => {
                                    setSelectedLocationId(null);
                                }}
                                className="m-r-xs"
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={editLocation}
                                disabled={
                                    editLocationName ===
                                    selectedLocation!.locationFriendlyName
                                }
                                bsStyle="primary"
                            >
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
            { (response && response?.locations.length > 5) &&
                <div className="section-footer">
                    <button
                        onClick={() => {
                            setShowAll(!showAll);
                        }}
                    >{ showAll ? "Show Less" : "Show All" }</button>
                </div>
            }
        </section>
    );
};

export const sortLocations = (a: Location, b: Location) => {
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;
    return a.locationFriendlyName.localeCompare(b.locationFriendlyName);
};

export default Locations;
