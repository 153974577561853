/* eslint-disable */
import { Container } from '../unstated-fork/unstated';
import OrganisationApi from '../api/organisation/Organisation';
import { OrganisationDTO } from '../api/organisation/ResponseTypes';
import { setValue } from 'src/db/KeyValueCache';

interface TeamsState {
    loaded: boolean;
    organisation: null | OrganisationDTO;
}

export default class TeamsContainer extends Container<TeamsState> {
    state = {
        loaded: false,
        organisation: null
    } as TeamsState;

    constructor() {
        super();
        this.getOrganisation();
    }

    getOrganisation = async () => {

        // const hasOrg = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') || '{}').organisationId;
        // if (!hasOrg) {
        //     this.setState({
        //         loaded: true
        //     })
        //     return;
        // }

        const org = await OrganisationApi.getOwnOrganisation()
        setValue('organisation', org.data)
        
        // @ts-ignore
        if (org !== true) {
            this.setState({
                organisation: org.data || null,
                loaded: true
            })
        } else {
            this.setState({
                loaded: true
            })
        }

    };

}
