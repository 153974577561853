/* eslint-disable */
import React from 'react';
import '../../css/People.css';
import CompanyApi from '../../api/company/Company';
import Utilities from 'src/Utilities';
import ChatBotApi from 'src/api/chatbot/ChatBot';
import { Badge, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import TimePresetSlider from '../availability/TimePresetSlider';
import theme from 'src/css/theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SearchParams, State } from './People';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

class PeopleFilter extends React.Component<
    State & {
        openFilterDropdown: (dropdown: "sort" | "timepreset" | "locations" | "attributes" | "representations" | null) => void;
        filter: (searchParams: SearchParams) => void;
        setView: (view: "collapsed" | "hovered" | "expanded") => void;
        setSkillsFilter: (filter: string) => void;
        setSkillsSearchType: (type: "AND" | "OR") => void;
        setRepresentationsFilter: (filter: string) => void;
        setRepresentationsSearchType: (type: "AND" | "OR") => void;
        onDayCountChange: (days: number) => void;
        updateFilters: boolean;
    },
    {
        searchParams: SearchParams;
        loadingMessage: string | null;
        aiPromptModal: boolean;
        aiPromptModalText: string;
        skillsToShow: number;
    }
> {
    datesRef: React.RefObject<HTMLDivElement> = React.createRef();
    peopleRef: React.RefObject<HTMLDivElement> = React.createRef();
    scrollChecking: boolean = false;
    inputDebounce: any = null;
    startTime: number = 0;
    state = {
        searchParams: {
            ...this.props.searchParams
        },
        loadingMessage: null,
        aiPromptModal: false,
        aiPromptModalText: "",
        skillsToShow: 100 // todo auto paginate
    }

    componentWillUpdate() {
        this.startTime = performance.now();
    }

    componentDidUpdate(prevProps: any) {
        const endTime = performance.now();
        // console.log('[Filter] Render time:', endTime - this.startTime);

        if (!prevProps.updateFilters && this.props.updateFilters) {
            console.log("Update PRops Filters")
            this.setState({
                searchParams: {
                    ...this.props.searchParams
                }
            });
        }
    }

    componentDidMount(): void {

    }

    public render() {
        const numberOfAvailabilityDays = this.props.view === "expanded" ? this.props.expandedDaysToDisplay : this.props.view === "hovered" ? 6 : 0;
        const startDay = new Date();
        const dayHashRange = [...Array(numberOfAvailabilityDays).keys()].map((i) => {
            return Utilities.formatDate(Utilities.dateAdd(startDay, "day", i), "YYYY-MM-DD");
        });

        return (
            <div className='filters'>
                { (this.props.view === "collapsed") &&
                    <button onClick={() => {
                        this.props.setView("expanded");
                    }}>
                        <i className="fas fa-search"></i>
                    </button>
                }
                { (this.props.view === "hovered" || this.props.view === "expanded") &&
                    <React.Fragment>
                        <button onClick={() => {
                            this.props.setView(this.props.view === "expanded" ? "collapsed" : "expanded");
                        }}>
                            <i className={"fas " + (this.props.view === "hovered" ? "fa-arrows-alt-h" : "fa-times")}></i>
                        </button>
                        {/* <button onClick={() => {
                            if (this.props.view !== "expanded") {
                                this.props.setView("expanded");
                            }
                            this.setState({
                                aiPromptModal: true,
                            })
                        }}>
                            <i className="fa-solid fa-wand-magic-sparkles"></i>
                        </button> */}
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-clock"></i>
                                <span>Preset / Period</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        if (this.state.searchParams.customTimePreset) {
                                            e.preventDefault();
                                            // @ts-ignore
                                            e.target.blur();
                                            this.props.openFilterDropdown("timepreset");
                                        }
                                    }}
                                    value={this.state.searchParams.customTimePreset ? 0 : this.state.searchParams.timePreset.id}
                                    onChange={(e) => {
                                        const preset = this.props.timePresets.find(p => p.id === e.target.value);
                                        if (preset) {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    timePreset: preset,
                                                    customTimePreset: null
                                                },
                                            }, () => {
                                                this.props.openFilterDropdown(null);
                                                this.props.filter(this.state.searchParams);
                                            })
                                        } else {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: {
                                                        startHour: 4,
                                                        endHour: 22,
                                                        startMinute: 0,
                                                        endMinute: 0
                                                    }
                                                }
                                            }, () => {
                                                this.props.openFilterDropdown("timepreset");
                                                this.props.filter(this.state.searchParams);
                                            });
                                        }
                                    }}
                                >
                                    {this.props.timePresets.map((preset, i) => {
                                        return (
                                            <option value={preset.id}>
                                                {(preset.startHour === 0 ? "00" : preset.startHour < 10 ? ("0" + preset.startHour) : preset.startHour)}:
                                                {(preset.startMinute === 0 ? "00" : preset.startMinute < 10 ? ("0" + preset.startMinute) : preset.startMinute)} - 
                                                {(preset.endHour === 0 ? "00" : preset.endHour < 10 ? ("0" + preset.endHour) : preset.endHour)}:
                                                {(preset.endMinute === 0 ? "00" : preset.endMinute < 10 ? ("0" + preset.endMinute) : preset.endMinute)}{' '}
                                                [{preset.name}]
                                            </option>
                                        )
                                    }) }
                                    <option value={0}>
                                        { (this.state.searchParams.customTimePreset) &&
                                            (this.state.searchParams.customTimePreset.startHour === 0 ? "00" : this.state.searchParams.customTimePreset.startHour < 10 ? ("0" + this.state.searchParams.customTimePreset.startHour) : this.state.searchParams.customTimePreset.startHour) + ":" + 
                                            (this.state.searchParams.customTimePreset.startMinute === 0 ? "00" : this.state.searchParams.customTimePreset.startMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.startMinute) : this.state.searchParams.customTimePreset.startMinute) + "-" +
                                            (this.state.searchParams.customTimePreset.endHour === 0 ? "00" : this.state.searchParams.customTimePreset.endHour < 10 ? ("0" + this.state.searchParams.customTimePreset.endHour) : this.state.searchParams.customTimePreset.endHour) + ":" + 
                                            (this.state.searchParams.customTimePreset.endMinute === 0 ? "00" : this.state.searchParams.customTimePreset.endMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.endMinute) : this.state.searchParams.customTimePreset.endMinute) + " "
                                        }
                                        [Custom]
                                    </option>
                                </select>
                            </div>
                            { (this.props.filterDropdown === "timepreset" && this.state.searchParams.customTimePreset) &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Set the required start, and end time by adjusting the below slider</h3>
                                    </div>
                                    <TimePresetSlider
                                        compactDrag={true}
                                        overnight={ this.state.searchParams.customTimePreset.startHour > this.state.searchParams.customTimePreset.endHour}
                                        values={[this.state.searchParams.customTimePreset.startHour + this.state.searchParams.customTimePreset.startMinute / 60, this.state.searchParams.customTimePreset.endHour + this.state.searchParams.customTimePreset.endMinute / 60]}
                                        onFinalChange={(values) => {
                                            let customPreset: any = { };
                                            customPreset!.startHour = Math.floor(values[0]);
                                            customPreset!.startMinute = Utilities.getDecimal(values[0]) * 60;
                                            customPreset!.endHour = Math.floor(values[1]);
                                            customPreset!.endMinute = Utilities.getDecimal(values[1]) * 60;

                                            customPreset.startMinute = Math.round(customPreset.startMinute);
                                            customPreset.endMinute = Math.round(customPreset.endMinute);

                                            customPreset!.startTime = 
                                                (customPreset.startHour < 10 ? "0" + customPreset.startHour : customPreset.startHour) + 
                                                ":" +
                                                (customPreset.startMinute < 10 ? "0" + customPreset.startMinute : customPreset.startMinute);
                                            customPreset!.endTime = 
                                                (customPreset.endHour < 10 ? "0" + customPreset.endHour : customPreset.endHour) + 
                                                ":" +
                                                (customPreset.endMinute < 10 ? "0" + customPreset.endMinute : customPreset.endMinute);

                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: customPreset
                                                }
                                            }, () => {
                                                this.props.filter(this.state.searchParams);
                                            })
                                        }}
                                        onChange={(values) => {

                                        }}
                                    />
                                    <div className='buttons' style={{ display: "flex" }}>
                                        <button className='reset' style={{ background: theme.colours.red2 }} onClick={() => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    customTimePreset: null
                                                },
                                            }, () => {
                                                this.props.openFilterDropdown(null);
                                                this.props.filter(this.state.searchParams);
                                            })
                                        }}>Back to Presets</button>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-infinity"></i>
                                <span>Global Search</span>
                            </h3>
                            <div className="simple-input">
                                <input
                                    type="text"
                                    value={this.state.searchParams.globalSearch}
                                    id="search-by-all-input"
                                    onChange={({ target: { name, value } }) => {
                                        let searchParams = this.state.searchParams;
                                        searchParams.globalSearch = value;
                                        this.setState({
                                            searchParams: searchParams
                                        })
                                        clearInterval(this.inputDebounce);
                                        this.inputDebounce = setTimeout(() => { this.props.filter(this.state.searchParams); }, 500);
                                    }}
                                    placeholder="Search in all Fields"
                                />
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-infinity"></i>
                                <span>Order By</span>
                            </h3>
                            <div className='simple-select'>
                                <select
                                    value={this.state.searchParams.sortType}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                sortType: e.target.value as "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name",
                                                mostAvailbleDates: e.target.value !== "most-available" ? [] : [0, 1, 2, 3, 4, 5, 6].map((i) => {
                                                    return Utilities.formatDate(Utilities.dateAdd(new Date(), "day", i), "YYYY-MM-DD");
                                                })
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        });
                                    }}
                                >
                                    {[
                                        { id: "last-updated", name: "Last Updated" },
                                        { id: "name", name: "Name" },
                                        { id: "overall-rating", name: "Overall Rating" },
                                        { id: "own-rating", name: "My Rating" },
                                        { id: "distance", name: "Distance" },
                                        { id: "most-available", name: "Most Available" }
                                    ].map((sort, i) => {
                                        return (
                                            <option value={sort.id}>
                                                {sort.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-signature"></i>
                                <span>Name</span>
                            </h3>
                            <div className="simple-input">
                                <input
                                    type="text"
                                    value={this.state.searchParams.name}
                                    id="search-by-all-input"
                                    onChange={({ target: { name, value } }) => {
                                        let searchParams = this.state.searchParams;
                                        searchParams.name = value;
                                        this.setState({
                                            searchParams: searchParams
                                        })
                                        clearInterval(this.inputDebounce);
                                        this.inputDebounce = setTimeout(() => { this.props.filter(this.state.searchParams); }, 500);
                                    }}
                                    placeholder="Search by Name"
                                />
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-users"></i>
                                <span>Teams</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.teamId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                teamId: parseInt(e.target.value),
                                                groupId: parseInt(e.target.value) // deault everyone group has same id as team
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.teams.map((team, i) => {
                                        return (
                                            <option value={team.id}>
                                                {team.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset">
                            <h3>
                                <i className="far fa-folder"></i>
                                <span>Groups</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.groupId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                groupId: parseInt(e.target.value) 
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.groups.filter(g => g.teamId === this.state.searchParams.teamId).map((group, i) => {
                                        return (
                                            <option value={group.id}>
                                                {group.name}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                        <div className="filter timepreset" onClick={() => {
                            if (this.props.filterDropdown !== "attributes") {
                                this.props.openFilterDropdown("attributes");
                            }
                        }}>
                            <div className='head' onClick={() => {
                                if (this.props.filterDropdown === "attributes") {
                                    this.props.openFilterDropdown(null);
                                }
                            }}>
                                <h3>
                                    <i className="fas fa-tag"></i>
                                    <span>Sectors, Skills</span>
                                </h3>
                                <div className="simple-tags">
                                    { this.state.searchParams.attributeIds.length === 0 ? "No Skills Selected" : (this.state.searchParams.attributeIds.length > 1 ? (this.state.searchParams.attributeIds.length + " Skills Selected") : "1 Skill Selected") }
                                </div>
                                </div>
                            { (this.props.filterDropdown === "attributes") &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Select/Search Sectors, Subsectors, Skills</h3>
                                        <div className='sub-filters'>
                                            <input
                                                type="text"
                                                value={this.props.skillsFilter}
                                                id="search-by-all-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.props.setSkillsFilter(value.toLowerCase());
                                                }}
                                                placeholder="Search Skills"
                                            />
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.props.skillsSearchType}
                                                exclusive
                                                onChange={(e, newType) => {
                                                    this.props.setSkillsSearchType(newType as "AND" | "OR");
                                                }}
                                            >
                                                <ToggleButton value="AND">AND</ToggleButton>
                                                <ToggleButton value="OR">OR</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </div>
                                    <div className='attributes-wrapper'>
                                        { [... new Set(this.props.userAttributes.map(attr => attr.skillId))].map((skillId, skillIdI) => {
                                            const skill = this.props.userAttributes.find(attr => attr.skillId === skillId);
                                            if (!skill) {
                                                return null;
                                            }

                                            const match = !this.props.skillsFilter || skill.skillName.toLowerCase().includes(this.props.skillsFilter);
                                            if (!match) {
                                                return null;
                                            }

                                            if (!this.props.skillsFilter && skillIdI >= this.state.skillsToShow) {
                                                return null;
                                            }
                                            
                                            const compoundId = `${skill.sectorId}-${skill.subSectorId}-${skill.skillId}`;
                                            return (
                                                <div className='skill'>
                                                    <FormControlLabel
                                                        label={<span>
                                                            <span>{skill.skillName} {(skill.subSectorName && skill.sectorName) ? <span className='sector-subsector'>[{skill.subSectorName} / {skill.sectorName}]</span> : ""}</span>
                                                            <Badge badgeContent={skill.userIds.length} color="primary"></Badge>
                                                        </span>}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.searchParams.attributeIds.includes(compoundId)}
                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                onChange={(e) => {
                                                                    let searchParams = this.state.searchParams;
                                                                    if (e.target.checked) {
                                                                        searchParams.attributeIds.push(compoundId);
                                                                    } else {
                                                                        searchParams.attributeIds = searchParams.attributeIds.filter(id => id !== compoundId);
                                                                    }
                                                                    this.setState({
                                                                        searchParams: searchParams
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )
                                        }) }
                                        {/* { [... new Set(this.props.userAttributes.map(attr => attr.sectorId))].map((sectorId) => {
                                            const sectorName = this.props.userAttributes.find(attr => attr.sectorId === sectorId)?.sectorName;
                                            const anySkillsMatch = !this.props.skillsFilter || this.props.userAttributes.filter(attr => attr.sectorId === sectorId).some(attr => attr.skillName.toLowerCase().includes(this.props.skillsFilter));
                                        
                                            if (!anySkillsMatch) {
                                                return null;
                                            }

                                            return (
                                                <React.Fragment>
                                                    <h4>{sectorName || "General Sectors"}</h4>
                                                    { [...new Set(this.props.userAttributes.filter(attr => attr.sectorId === sectorId).map(s => s.subSectorId))].map((subSectorId) => {
                                                        const subSector = this.props.userAttributes.find(attr => attr.subSectorId === subSectorId);
                                                        if (!subSector) {
                                                            return null;
                                                        }

                                                        const anySkillsMatch = !this.props.skillsFilter || this.props.userAttributes.filter(attr => attr.subSectorId === subSectorId).some(attr => attr.skillName.toLowerCase().includes(this.props.skillsFilter));
                                                        if (!anySkillsMatch) {
                                                            return null;
                                                        }

                                                        return (
                                                            <React.Fragment>
                                                                <h5>{subSector.subSectorName || "General Sub-sectors"}</h5>
                                                                <div className='skills'>
                                                                    { [...new Set(this.props.userAttributes.filter(attr => attr.subSectorId === subSector.subSectorId).map(ss => ss.skillId))].map((skillId) => {
                                                                        const skill = this.props.userAttributes.find(attr => attr.skillId === skillId);
                                                                        if (!skill) {
                                                                            return null;
                                                                        }

                                                                        const match = !this.props.skillsFilter || skill.skillName.toLowerCase().includes(this.props.skillsFilter);
                                                                        if (!match) {
                                                                            return null;
                                                                        }
                                                                        
                                                                        const compoundId = `${sectorId}-${subSectorId}-${skillId}`;
                                                                        return (
                                                                            <div className='skill'>
                                                                                <FormControlLabel
                                                                                    label={<span>
                                                                                        <span>{skill.skillName}</span>
                                                                                        <Badge badgeContent={skill.userIds.length} color="primary"></Badge>
                                                                                    </span>}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={this.state.searchParams.attributeIds.includes(compoundId)}
                                                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                                            onChange={(e) => {
                                                                                                let searchParams = this.state.searchParams;
                                                                                                if (e.target.checked) {
                                                                                                    searchParams.attributeIds.push(compoundId);
                                                                                                } else {
                                                                                                    searchParams.attributeIds = searchParams.attributeIds.filter(id => id !== compoundId);
                                                                                                }
                                                                                                this.setState({
                                                                                                    searchParams: searchParams
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }) }
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }) }
                                                </React.Fragment>
                                            )
                                        }) } */}
                                    </div>
                                    <div className='buttons'>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                            this.props.filter(this.state.searchParams);
                                            // TODO NEED TO DETECT OUTCLICKS 
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="filter timepreset" onClick={(e) => {
                            e.stopPropagation();
                            if (this.props.filterDropdown !== "representations") {
                                this.props.openFilterDropdown("representations");
                            }
                        }}>
                            <div className='head' onClick={() => {
                                if (this.props.filterDropdown === "representations") {
                                    this.props.openFilterDropdown(null);
                                }
                            }}>
                                <h3>
                                    <i className="fas fa-building"></i>
                                    <span>Representing Agencies</span>
                                </h3>
                                <div className="simple-tags">
                                    { this.state.searchParams.representingAgencies.length === 0 ? "None Selected" : (this.state.searchParams.representingAgencies.length > 1 ? (this.state.searchParams.representingAgencies.length + " Selected") : "1 Selected") }
                                </div>
                            </div>
                            { (this.props.filterDropdown === "representations") &&
                                <div className='filter-dropdown'>
                                    <div className='header'>
                                        <h3>Select Represeting Agencies</h3>
                                        <div className='sub-filters'>
                                            <input
                                                type="text"
                                                value={this.props.representationsFilter}
                                                id="search-by-all-input"
                                                onChange={({ target: { name, value } }) => {
                                                    this.props.setRepresentationsFilter(value.toLowerCase());
                                                }}
                                                placeholder="Search Agencies"
                                            />
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.props.representationsSearchType}
                                                exclusive
                                                onChange={(e, newType) => {
                                                    this.props.setRepresentationsSearchType(newType as "AND" | "OR");
                                                }}
                                            >
                                                <ToggleButton value="AND">AND</ToggleButton>
                                                <ToggleButton value="OR">OR</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </div>
                                    <div className='representations-wrapper'>
                                        { this.props.userRepresentations.map((agency) => {
                                            const orgNameMatches = !this.props.representationsFilter || agency.name.toLowerCase().includes(this.props.representationsFilter);
                                            if (!orgNameMatches || !agency.id) {
                                                return null;
                                            }
                                            return (
                                                <div className='representing-agency' data-id={agency.id}>
                                                    <FormControlLabel
                                                        label={<span>
                                                            <LazyLoadImage
                                                                height={36}
                                                                src={CompanyApi.getOrganisationProfileImageUrl(agency.id)}
                                                                width={36}
                                                            />
                                                            <span>{agency.name}</span>
                                                            <Badge badgeContent={agency.userIds.length} color="primary"></Badge>
                                                        </span>}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.searchParams.representingAgencies.includes(agency.id)}
                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                onChange={(e) => {
                                                                    let searchParams = this.state.searchParams;
                                                                    if (e.target.checked) {
                                                                        searchParams.representingAgencies.push(agency.id);
                                                                    } else {
                                                                        searchParams.representingAgencies = searchParams.representingAgencies.filter(id => id !== agency.id);
                                                                    }
                                                                    this.setState({
                                                                        searchParams: searchParams
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )
                                        }) }
                                    </div>
                                    <div className='buttons'>
                                        <button onClick={() => {
                                            this.props.openFilterDropdown(null);
                                            this.props.filter(this.state.searchParams);
                                        }}>Apply & Close</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-route"></i>
                                <span>Show Distance from</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.state.searchParams.locationId}
                                    onChange={(e) => {
                                        this.setState({
                                            searchParams: {
                                                ...this.state.searchParams,
                                                locationId: e.target.value
                                            }
                                        }, () => {
                                            this.props.filter(this.state.searchParams);
                                        })
                                    }}
                                >
                                    {this.props.locations.map((location, i) => {
                                        return (
                                            <option value={location.id}>
                                                {location.locationFriendlyAddress}
                                                {location.locationPlaceName ? " -" + location.locationPlaceName + "" : ""}
                                                {location.postCode ? " -" + location.postCode + "" : ""}
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>

                        <div className="filter timepreset">
                            <h3>
                                <i className="fas fa-route"></i>
                                <span>Days to show</span>
                            </h3>
                            <div className='simple-select'>
                                <select 
                                    onClick={(e) => {
                                        this.props.openFilterDropdown(null);
                                    }}
                                    value={this.props.expandedDaysToDisplay}
                                    onChange={(e) => {
                                        this.props.onDayCountChange(parseInt(e.target.value));
                                    }}
                                >
                                    {[15, 20, 30, 45, 60, 75, 90].map((days, i) => {
                                        return (
                                            <option value={days}>
                                                {days} Days
                                            </option>
                                        )
                                    }) }
                                </select>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <FullScreenLoader view="chat" loadingMessage={this.state.loadingMessage} />
            </div>
        );
    }
}

export default PeopleFilter;