/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { ExtractRequest, ExtractResponse } from './RequestTypes';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';

const apiCalls = {
    message(request: ExtractRequest) {
        return afetch.request<ExtractRequest, ExtractResponse>(
            `${AppConfig.Settings.api.externalUri}/chatbot`,
            'POST',
            request,
            'Unable to response from chatbot'
        );
    }
};

export default apiCalls;
