/* eslint-disable */
import './css/style.css';
import './css/site.css';
import './css/print.css';
import './fonts/pe-icon-7-stroke/css/pe-icon-7-stroke.css';
import './fonts/pe-icon-7-stroke/css/helper.css';
import 'font-awesome/css/font-awesome.css';
import JwtDecode from 'jwt-decode';
import Dexie from 'dexie';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import fetch from 'cross-fetch';
import { Configuration } from './components/config/Config';

import { Provider as Unstated } from './unstated-fork/unstated';
import * as RoutesModule from './routes';
import AppConfig from './components/config/Config';
import Auth from './components/auth/Auth';
import { RegularExpressions } from './constants';
import { EphemeralAccessTokenStorageKey } from './components/layout/EphemeralAccessLayout';
import Analytics from './services/analytics.service';
import StateManager from './state-containers/state-manager';
import history from './history';
import { store } from './ue-store';
import InternalTracker from './InternalTracker';
import ErrorBoundary from './components/util/ErrorBoundary';
import { toast } from 'react-toastify';
import ContactApi from './api/contacts/Contacts';
import { unauthenticatedPages, trulyUnauthenticatedPages, trulyUnauthenticatedPagesWildcard } from './routes';

import SignalRService from './services/signalr.service';
import { OfferEventDeletedDto, OfferRecipientDtoChange, OfferResponseTypes } from './api/offers/ResponseTypes';

let routes = RoutesModule.routes;

function renderApp() {
    ReactDOM.render(
        <ErrorBoundary>
            <Unstated>
                <Provider store={store}>
                    <Router history={history} children={routes} />
                </Provider>
            </Unstated>
        </ErrorBoundary>,
        document.getElementById('react-app')
    );
}

const readExternalAccessToken = () => {
    // If they are loading an externally accessible page
    if (window.location.pathname.startsWith('/external/')) {
        // Read the token from the query string
        const tokenResult = RegularExpressions.tokenQueryParam.exec(
            window.location.search
        );
        if (tokenResult) {
            const token = decodeURIComponent(tokenResult[1]).replace("?api-version=1.0", "");

            // Add token to local storage where afetch will check and pass up in requests, if present
            localStorage.setItem(EphemeralAccessTokenStorageKey, token);
        }
    }
};

async function initDb() {
    const db = new Dexie("updatedge");

    db.version(13).stores({
        channelIdCache: 'userId',
        chatThreadMessagtes: 'sid',
        keyValueCache: 'key',
        syncCache: 'key,entitiyId,entityType,value',
    });

    (window as any).localIndexedDb = db;
}

startApp();

function onWindowFocus() {
    // checkImpersonatedTokenExpiry
    const impersonatedToken = localStorage.getItem("impersonatedAccessToken");
    const impersonatedTokenExpire = localStorage.getItem("impersonatedAccessTokenExpired");
    if (impersonatedToken && impersonatedTokenExpire && new Date(impersonatedTokenExpire) < new Date()) {
        localStorage.removeItem('impersonatedAccessToken');
        localStorage.removeItem('impersonatedAccessTokenExpired');
        localStorage.removeItem("user");
        window.location.href = '/internal/users';
    }
}

async function startApp() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('./azure-push-sw.js')
            .then((registration) => {
                console.log(registration)
                console.log('___ Service Worker registered with scope:', registration.scope);
            })
            .catch((err) => {
                console.log('____Service Worker registration failed:', err);
            });
    } else {
        console.log('____Service Worker not supported');
    }

    window.addEventListener("focus", onWindowFocus);

    // dyanmic event listener to all elements wiht data-report-blurred='true'
    document.addEventListener('click', (event) => {
        // @ts-ignore
        if (event?.target?.getAttribute('data-report-blurred') === 'true') {
            // @ts-ignore
            const userId = event?.target?.getAttribute('data-user-id');
            event.stopImmediatePropagation();
            event.preventDefault();
            // @ts-ignore
            document.querySelectorAll('[data-report-blurred="true"][data-user-id="' + userId + '"]').forEach((el) => {
                el.setAttribute('data-report-blurred', 'false');
            });
        }
    }, true);

    onWindowFocus();

    const delegate = (selector) => (cb) => (e) => e.target.matches(selector) && cb(e);
    const inputDelegate = delegate('input');
    // document.body.addEventListener('focusin', inputDelegate((el) => console.log('focus in', el.target.name)));
    document.body.addEventListener('focusout', inputDelegate((el) => {
        window.scrollTo(0,0)
    }));

    (window as any).lastDateFocused = new Date();

    initDb();

    const isMobilePage = window.location.pathname.startsWith('/mobile/');

    setTimeout(() => {
        initSignalR();
    }, 500)

    // load settings
    let config = AppConfig.Settings;

    const envOverwrite = window.location.href.split("env=")[1];
    if (envOverwrite) {
        localStorage.setItem("env", envOverwrite);
        window.location.href = "/";
        return;
    }

    if (!localStorage.getItem("availabilityViewPreferences")) { 
        localStorage.setItem("availabilityViewPreferences", JSON.stringify({ }));
    }

    setInterval(() => {
        if ((window as any).lastUrl !== window.location.href) {
            (window as any).lastUrl = window.location.href;
            InternalTracker.trackEvent("Page View " + window.location.pathname.substr(1).replace(/\//g, "-"));
        }
    }, 250)

    // check for mobile page
    if (isMobilePage) {
        fetch(config.api.externalUri + '/token/token?api-version=1.0')
            .then((response) => {
                return response.json();
            })
            .then((obj) => {
                // set the cookie for later retrieval by mobile pages (passed from app)
                Auth.setCookie(
                    'AuthorizationPassthrough',
                    obj.authorizationPassthrough
                );

                // complete initialisation
                initApp(
                    isMobilePage,
                    config.authentication.usePortalAuthOnMobilePages
                );
            });
    } else {
        // start app as normal (portal)
        initApp(isMobilePage, config.authentication.usePortalAuthOnMobilePages);
    }

    const localUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
    if (localUser) {
        (window as any).globalUserId = localUser.id
    }
}

async function initSignalR() {
    if (window.location.pathname.startsWith("/external")) {
        return;
    }
    
    const hubConnection = SignalRService.create('portal');

    const onNotificationShown = (from?: string) => {
        // Seen through signalR, assume all previous ones seen
        if (!(window as any).notificationsPanelOpen) {
            // localStorage.setItem("notificationsLastSeen", (new Date()).toISOString())
        }
        if ((window as any).reloadNotificationOnNew) {
            (window as any).reloadNotificationOnNew();
        }
    }

    hubConnection.on('EventsUpdated', (userId: string, userFullName: string) => {
        onNotificationShown();
        if ((window as any).onSignalREventsUpdatedAvailabilityGrid) (window as any).onSignalREventsUpdatedAvailabilityGrid(userId);
        if ((window as any).onSignalREventsUpdatedContactProfile) (window as any).onSignalREventsUpdatedContactProfile(userId);
        if ((window as any).onSignalREventsUpdatedWorkerSelector) (window as any).onSignalREventsUpdatedWorkerSelector(userId);
        if ((window as any).triggerPeopleGridRefresh) (window as any).triggerPeopleGridRefresh();
    });

    hubConnection.on('ContactStatusChanged', (data) => {
        onNotificationShown();
        if ((window as any).onSignalRContactStatusChangedAvailabilityGrid) (window as any).onSignalRContactStatusChangedAvailabilityGrid(data.id, data.contactId, data.actionTaken === 1);
        if ((window as any).triggerPeopleGridRefresh) (window as any).triggerPeopleGridRefresh();
        if ((window as any).triggerChatContactRefresh) (window as any).triggerChatContactRefresh();
    });
    
    hubConnection.on('PhoneNumberVerified', () => {
        onNotificationShown();
        toast.success('Your phone number has been verified')
        if ((window as any).onSignalRPhoneNumberVerifiedUserProfile) (window as any).onSignalRPhoneNumberVerifiedUserProfile();
    })

    hubConnection.on('AccountAndOrgVerified', () => {
        onNotificationShown();
        toast.success('Your account has been verified')
        if ((window as any).onSignalRAccountAndOrgVerifiedVerifiedUserProfile) (window as any).onSignalRAccountAndOrgVerifiedVerifiedUserProfile();
    })

    hubConnection.on('UserOfferUpdated', ( recipientUserId, userId, userName, update: OfferRecipientDtoChange ) => {
        onNotificationShown('UserOfferUpdated');
        if ((window as any).onSignalRUserOfferUpdated) (window as any).onSignalRUserOfferUpdated(update);
    });
    hubConnection.on('UserOfferEventDeleted', ( recipientUserId, userId, userName, offerEventDeleted: OfferEventDeletedDto) => {
        onNotificationShown();
        // toast.error(`${userName} deleted confirmed offer ${offerEventDeleted.offerTitle}.`)
        if ((window as any).onSignalRUserOfferEventDeleted) (window as any).onSignalRUserOfferEventDeleted(offerEventDeleted);
    });
    hubConnection.on('FileSharedPrivately', (fileId: number) => {
       onNotificationShown();
       if ((window as any).onSignalRFileShared) (window as any).onSignalRFileShared(fileId);
    });

    hubConnection.on('FileSharedPublicly', (fileId: number) => {
       if ((window as any).onSignalRFileShared) (window as any).onSignalRFileShared(fileId);
    });

    hubConnection.on('ReferralReceived', (recipientUserId: string, referringUserId: string, referringUserName) => {
        if ((window as any).onSignalRReferralReceived) (window as any).onSignalRReferralReceived();
        onNotificationShown();
    });

    hubConnection.on('ReferralCompleted', (recipientUserId: string, referralId: string) => {
        onNotificationShown();
    });

    hubConnection.on('UserRatingUpdated', () => {
        if ((window as any).onSignalRUserRatingUpdated) (window as any).onSignalRUserRatingUpdated();
    })
}

function initApp(isMobilePage: boolean, usePortalAuthOnMobilePages: boolean) {

    if (window.location.href.indexOf("?cypress") !== -1) {
        (window as any).Cypress = (window as any).Cypress || true;
    }

    // dont' use hello.js to authenticate users if usePortalAuthOnMobilePages or it's not a mobile route.
    // this allows desktop development users to authenticate using the normal B2C flow.
    if (
        (!isMobilePage || usePortalAuthOnMobilePages === true) && 
        (!localStorage.getItem("access_token_otp")) &&
        (trulyUnauthenticatedPages.indexOf(window.location.pathname) === -1) &&
        (!trulyUnauthenticatedPagesWildcard.find((x) => window.location.pathname.startsWith(x)))
    ) {
        // register hello.js profiles for connection to b2c auth
        Auth.registerProfiles(
            AppConfig.Settings.authentication.appId,
            AppConfig.Settings.authentication.scope,
            AppConfig.Settings.authentication.signInAndUpPolicyName,
            AppConfig.Settings.authentication.tenantName,
            AppConfig.Settings.authentication.redirectUri
        );
    }

    // Check for the presence of an external access token and store it
    readExternalAccessToken();

    // Init Insights and set userId on future tracks if possible
    Analytics.init();

    StateManager.hook();

    if (window.location.pathname.startsWith("/external")) {
        const tokenResult = RegularExpressions.tokenQueryParam.exec(
            window.location.search
        );

        if (tokenResult) {
            const token = decodeURIComponent(tokenResult[1]);
            if (token) {
                const decoded = JwtDecode(token);
                if (decoded.urn && true) { // provider id inc
                    console.log("setUserIdTO" + decoded.urn)
                }
            }
        }
    } else {
        const { oid: userId, emails } = Auth.getDecodedToken();

        InternalTracker.init();
    }

    renderApp();
}