/* eslint-disable */
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Subscribe } from '../../unstated-fork/unstated';
import theme from '../../css/theme';
import {
    UserTypeContainer,
    UserType
} from '../../state-containers/user-type.container';
import Hyperlink from '../ui-components/Hyperlink';
import AuthApi from '../../components/auth/Auth';
import OrganisationApi from '../../api/organisation/Organisation';
import CompanyApi from '../../api/company/Company';
import ProfileApi from '../../api/profile/Profile';
import './EnhancedSignup.css';
import { ProfileImage } from '../ui-components/ProfileImage';
import InlineSelect from '../ui-components/InlineSelect';
import IndustrySelector from '../profile/IndustrySelector';
import { LookupItem } from 'src/api/lookups/ResponseTypes';
import { toast } from 'react-toastify';
import LocationPicker from '../profile/LocationPicker';
import DashboardContainer from 'src/state-containers/dashboard.container';
import history from '../../history';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import InternalTracker from '../../InternalTracker';
import { actionCreators as profileActionCreators } from '../../store/Profile';
import Utilities from 'src/Utilities';
import Dialog from '../ui-components/Dialog';
import PhoneInput from 'react-phone-input-2';
import { supportedImageFormats } from '../../constants';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import TimePresetsApi from '../../api/timepresets/TimePresets';
import { CircularProgress } from '@mui/material';

const env = window.location.host === "portal.updatedge.com" ? "prod" : "dev";

const SECTORS = [
    {"id": env === "prod" ? 1076 : 227, "value": "Education"}
]

const SUB_SECTORS = [
    // {"sectorId": 227, "value": "Primary", id: 324}, // Test ids
    // {"sectorId": 227, "value": "Secondary", id: 326} // Test ids
    {"sectorId": 227, "value": "Primary", id: env === "prod" ? 151 : 51},
    {"sectorId": 227, "value": "Secondary", id: env === "prod" ? 150 : 53}
]

const EDU_EMAILS = [
    ".sch.uk",
    ".ac.uk",
    ".edu"
]

interface PossibleOrgMatch {
    id: string;
    name: string;
    postcode?: string;
    alreadyAskedToJoined?: boolean;
    selfSelected?: boolean;
    cannotOverride?: boolean;
    adminName?: string;
    adminEmail?: string;
    canJoinAutomatically?: boolean;
    byDomain?: boolean;
    byNameLocation?: boolean;
}

const defaultProfileImage = "https://test-images.updatedge.com/images/profile/undefined.png"

interface State {
    firstName: string;
    lastName: string;
    orgName: string;
    orgLogo: string | File;
    orgLogoPreview: string;
    userType?: UserType;
    locationFullName: string;
    locationPlaceId: string;
    locationPlaceName: string;
    locationAddressComponents: google.maps.GeocoderAddressComponent[],
    sectors: LookupItem[];
    step: "profile" | "organisation" | "worker" | "error" | "waiting-verification",
    showLocationPicker: boolean;
    metadata: object | null,
    orgLogoFile: File | null,
    termsAccepted: boolean,
    privacyAccepted: boolean,
    loading: boolean,
    duplicateOrgError: boolean,
    skipOrgSetup: boolean,
    suggestionsLoadingStarted: boolean,
    disableSubsectorAutoFocus: boolean,
    phoneNumber: string;
    typedPhoneNumber: boolean;
    headline: string;
    newProfileImagePreviewUrl: string;
    newProfileImage: File | null;
    missingFields: string[];
    possibleOrgMatch: PossibleOrgMatch | null;
    possibleOrgMatches: PossibleOrgMatch[];
    loadingOrganisationResults: boolean;
}

export default class UserTypeModal extends React.Component<{}, State> {
    searchOrgDebounceTimeout: any;

    constructor(props) {
        super(props);
        
        const randomImageUrl = "https://ukliveue.blob.core.windows.net/images/dummy-profiles/" + (Utilities.randomIntFromInterval(1, 91)) + ".png";

        this.searchOrgDebounceTimeout = null;
        this.state = {
            userType: UserType.Unknown,
            firstName: '',
            lastName: '',
            orgName: '',
            orgLogoPreview: '',
            orgLogo: '',
            locationFullName: '',
            locationPlaceId: '',
            locationPlaceName: '',
            phoneNumber: '',
            typedPhoneNumber: false,
            headline: '',
            newProfileImagePreviewUrl: randomImageUrl,
            locationAddressComponents: [],
            newProfileImage: null,
            sectors: [],
            step: "profile",
            showLocationPicker: false,
            metadata: null,
            orgLogoFile: null,
            termsAccepted: false,
            privacyAccepted: false,
            loading: false,
            duplicateOrgError: false,
            skipOrgSetup: false,
            suggestionsLoadingStarted: false,
            disableSubsectorAutoFocus: false,
            missingFields: [],
            possibleOrgMatch: null,
            possibleOrgMatches: [],
            loadingOrganisationResults: false
        } as State;

        // this.userTypeModal = React.RefObject<HTMLDivElement>;
        // this.workerModal = React.RefObject<HTMLDivElement>;
        // this.getSuggestions();
        window.removeEventListener("focus", this.onFocus.bind(this));
        window.addEventListener("focus", this.onFocus.bind(this));
    }

    async componentDidMount(attemptCount = 0) {

        // Download state.newProfileImagePreviewUrl and store file in state.newProfileImage
        try {
            let response = await fetch(this.state.newProfileImagePreviewUrl);
            let data = await response.blob();
            let metadata = {
                type: 'image/' + (this.state.newProfileImagePreviewUrl.split(".").pop() === "png" ? "png" : "jpeg")
            };
            let file = new File([data], "image." + (this.state.newProfileImagePreviewUrl.split(".").pop() === "png" ? "png" : "jpg"), metadata);
            this.setState({
                newProfileImage: file
            })
        } catch(e) {
            console.log(e);
        }

        if (attemptCount === 0) {
            this.fetchDuplicateOrg();
        }
        
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        // New invited colleagues
        if (user && user.organisationId && user.userType !== 2 && user.userType !== 3) {
            this.setState({
                skipOrgSetup: true,
                userType: user.organisationIsHirer ? UserType.Hirer : UserType.Agency
            })
        } else if (user && (user.userType === 2 || user.userType === 3 || user.userType === 1)) {
            this.setState({
                step: (user.userType === 2 || user.userType === 3) ? "organisation" : "worker",
                userType: user.userType === 2 ? UserType.Hirer : user.userType === 3 ? UserType.Agency : user.userType === 1 ? UserType.Worker : UserType.Unknown,
            })
            Utilities.addChatWoot(true);
            setTimeout(() => {
                const cookieConsent = document.querySelector(".cookieConsent") as HTMLElement;
                if (cookieConsent) {
                    cookieConsent.style.display = "none";
                }
            }, 500)
            if (attemptCount === 0) {
                this.getSuggestions();
            }
        } else if (!user || !user.id) {
            if (attemptCount < 30) {
                setTimeout(() => {
                    this.componentDidMount(attemptCount + 1);
                }, 250)
            }
        } else {
            if (!this.state.suggestionsLoadingStarted) {
                this.getSuggestions();
                this.setState({
                    suggestionsLoadingStarted: true
                })
            }

            if (attemptCount < 30) {
                setTimeout(() => {
                    this.componentDidMount(attemptCount + 1);
                }, 250)
            }
        }
    }

    onFocus = () => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        if (user && user.organisationId && (user.userType === 2 || user.userType === 3) && document.getElementById("state-reload-button")) {
            window.location.reload();
        }

        if (user && this.state.possibleOrgMatch?.alreadyAskedToJoined) {
            this.checkOrgAcceptanceStatus();
        }
    }

    async checkOrgAcceptanceStatus() {
        const res = await ProfileApi.getProfile();
        if (res && res.results && res.results.organisationId) {
            Utilities.upsertLocalUser({
                organisationId: res.results.organisationId,
                organisationIsHirer: res.results.organisationIsHirer,
                organisationIsAgency: res.results.organisationIsAgency
            });
            window.location.reload();
        }
    }

    async createFileFromUrl(url){
        const urlToFetch = OrganisationApi.fetchExternalImage(url);
        let response = await fetch(urlToFetch);
        let data = await response.blob();
        let metadata = {
            type: 'image/' + (url.split(".").pop() === "png" ? "png" : "jpeg")
        };
        let file = new File([data], "image." + (url.split(".").pop() === "png" ? "png" : "jpg"), metadata);
        this.setState({
            orgLogoFile: file
        })
    }

    async fetchDuplicateOrg() {
        console.log("Attempting to fetch")
        const localUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
        if (localUser == null || !localUser.emailAddress) {
            setTimeout(() => {
                this.fetchDuplicateOrg();
            }, 1500);
            return
        }
        const duplicateOrg = await OrganisationApi.getOrganisationByDomain(localUser.emailAddress.split("@")[1]);
        // @ts-ignore
        if (duplicateOrg && duplicateOrg.data && duplicateOrg.data.id) {
            this.setState({
                possibleOrgMatch: {
                    // @ts-ignore
                    id: duplicateOrg.data.id,
                    // @ts-ignore
                    name: duplicateOrg.data.name,
                    // @ts-ignore
                    alreadyAskedToJoined: duplicateOrg.data.alreadyAskedToJoined,
                    // @ts-ignore
                    adminEmail: duplicateOrg.data.adminEmail,
                    // @ts-ignore
                    adminName: duplicateOrg.data.adminName,
                    // @ts-ignore
                    canJoinAutomatically: duplicateOrg.data.canJoinAutomatically,
                    byDomain: true
                }
            })
            // @ts-ignore
            if (duplicateOrg.data.alreadyAskedToJoined) {
                this.checkOrgAcceptanceStatus();
            }
        }
        console.log(duplicateOrg, "<<<< DUPE ORG")
    }

    async getSuggestions() {

        console.log("Getting suggestions")

        if (!localStorage.getItem("email") && (!document.getElementById("navigation-email-address") || !document.getElementById("navigation-email-address")!.innerHTML) ) {
            setTimeout(() => {
                this.getSuggestions();
            }, 250)
            return;
        }

        const email = localStorage.getItem("email") || document.getElementById("navigation-email-address")!.innerHTML || ""
        const suggestion = await OrganisationApi.getSuggestionsByEmail(email);
        const localUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;

        // this.fetchDuplicateOrg();

        console.log(suggestion, "<<<<<<<")
        
        this.setState({
                firstName: suggestion.firstName ? suggestion.firstName : this.state.firstName,
                lastName: suggestion.lastName ? suggestion.lastName : this.state.lastName,
                // @ts-ignore
                orgName: suggestion.clearbitOrgName ? suggestion.clearbitOrgName : suggestion.googleMapsOrgName ? suggestion.googleMapsOrgName : this.state.orgName,
                orgLogo: suggestion.metaserviceLogo ? suggestion.metaserviceLogo : suggestion.clearbitLogo ? suggestion.clearbitLogo : suggestion.faviconLogo ? suggestion.faviconLogo : this.state.orgLogo,
                locationFullName: suggestion.googleMapsOrgAddress ? suggestion.googleMapsOrgAddress.formatted_address : this.state.locationFullName,
                locationPlaceId: suggestion.googleMapsOrgAddress ? suggestion.googleMapsOrgAddress.placeId : this.state.locationPlaceId,
                locationPlaceName: "",
                locationAddressComponents: suggestion.googleMapsOrgAddress ? suggestion.googleMapsOrgAddress.address_components : [],
                userType: UserType.Unknown, // todo is this right? (localUser && (localUser.userType === 2 || localUser.userType === 3)) ? localUser.userType : suggestion.userType ? (suggestion.userType === "Hirer" ? UserType.Hirer : UserType.Agency) : UserType.Hirer, // this.state.userType,
                metadata: suggestion.metaserviceSchoolDetails ? suggestion.metaserviceSchoolDetails : this.state.metadata,
                // @ts-ignore
                sectors: suggestion.sectors ? 
                    suggestion.sectors.map(sector => {
                        const sectorId = SECTORS.find(s => s.value === sector)?.id;
                        if (!sectorId) return null;
                        return {
                            id: sectorId,
                            value: sector,
                            subSectors: SUB_SECTORS.filter(s => s.sectorId === sectorId && suggestion.subSectors.find(sub => sub === s.value))
                        }
                    }).filter(item => item !== null) : 
                    localUser && localUser.emailAddress && EDU_EMAILS.find(e => localUser.emailAddress.split("@")[1].includes(e)) ? 
                        SECTORS : this.state.sectors, // Map into subsectors into lookupitem
                // subSectors: suggestion.subSectors ? suggestion.subSectors : this.state.subSectors,
                disableSubsectorAutoFocus: localUser && localUser.emailAddress && EDU_EMAILS.find(e => localUser.emailAddress.includes(e))
        }, () => {
                if (this.state.orgLogo)
                    this.createFileFromUrl(this.state.orgLogo)
        })
    }

    async saveOrg() {
        let missingFields: string[] = [];

        if (!this.state.orgName) {
            missingFields.push("organization-name");
        }

        if (!this.state.locationPlaceId) {
            missingFields.push("organization-address");
        }

        if (this.state.sectors.length === 0) {
            missingFields.push("sector");
        }

        if ((this.state.sectors.length === 0) || (this.state.userType === UserType.Hirer && this.state.sectors.find(s => s && s.subSectors && s.subSectors.length === 0))) {
            missingFields.push("subsector");
        }

        if (!this.state.orgLogoFile) {
            missingFields.push("organization-logo");
        }

        this.setState({
            missingFields: missingFields
        })

        console.log(missingFields)

        if (missingFields.length > 0) {
            toast.error('Please fill in all required fields');
            return;
        }

        let createObj = {
            "name": this.state.orgName,
            "industries": this.state.sectors,
            "subSectors": this.state.sectors,
            "isHirer": this.state.userType === UserType.Hirer,
            "isAgency": this.state.userType === UserType.Agency,
            "locationPlaceId": this.state.locationPlaceId,
            "locationPlaceName": this.state.locationPlaceName,
            "locationFullName": this.state.locationFullName,
            "locationComponents": this.state.locationAddressComponents,
            "metadata": this.state.metadata ? { metadata: this.state.metadata } : null,
        }

        this.setState({
            loading: true
        })

        localStorage.setItem("justCreatedOrg", "true");

        // @ts-ignore
        await OrganisationApi.createOrganisation(createObj).then(
            (organisationId) => {

                profileActionCreators.getUserProfile()

                if (localStorage.getItem("user")) {
                    const user = JSON.parse(localStorage.getItem("user") || "{}");
                    user.organisationId = organisationId;
                    user.organisationIsHirer = this.state.userType === UserType.Hirer;
                    user.organisationIsAgency = this.state.userType === UserType.Agency;
                    localStorage.setItem("user", JSON.stringify(user));
                    (window as any).globalUserId = user.id;
                    InternalTracker.init();
                }

                Promise.all([
                    TimePresetsApi.getPresets(),
                    OrganisationApi.updateLocation(
                        this.state.locationPlaceId,
                        this.state.locationFullName,
                        this.state.locationPlaceName,
                        this.state.locationAddressComponents
                    ),
                    // @ts-ignore
                    CompanyApi.updateOrganisationProfileImage(this.state.orgLogoFile),
                    new Promise( async (resolve, reject) => {
                        const organisation = await OrganisationApi.getOrganisation(organisationId);
                        if (organisation?.data) {
                            localStorage.setItem("organisation", JSON.stringify(organisation?.data))
                        }
                        resolve(true)
                    })
                ]).then( async () => {
                    // trigger cache
                    await OrganisationApi.finalize();
                    this.setState({
                        step: "waiting-verification",
                    })
                    InternalTracker.trackEvent("Organisation Saved from Onboarding");
                    this.setState({
                        loading: false
                    })
                })
            }
        ).catch(e => {
            const status = e.status;
            console.log(e)
            if (e.status === 409 && e.name && e.id) {
                this.setState({
                    possibleOrgMatch: {
                        id: e.id,
                        name: e.name,
                        alreadyAskedToJoined: false,
                        cannotOverride: true,
                        byNameLocation: true
                    }
                })
                // this.setState({
                //     duplicateOrgError: true,
                //     step: "error"
                // })
                InternalTracker.trackEvent("Organisation Duplicate");
            } else {
                toast.error('Unable to create organization');
            }

            this.setState({
                loading: false
            })
        });
    }

    handleselectedFile(files: FileList | null) {
        if (files !== null) {
            if (0 > supportedImageFormats.indexOf(files[0].type)) {
                toast.error('Please select a jpg, png or gif file.');
            } else {
                this.setState({
                    newProfileImage: files[0],
                    newProfileImagePreviewUrl: URL.createObjectURL(files[0]!)
                })
                InternalTracker.trackEvent('Profile Image Updated');
            }
        }
    }

    searchByOrgName(orgName: string, debounced?: boolean) {
        this.setState({
            possibleOrgMatches: []
        })
        
        if (!orgName || !orgName.trim()) {
            return;
        }

        if (!debounced) {
            clearTimeout(this.searchOrgDebounceTimeout);
            this.searchOrgDebounceTimeout = setTimeout(() => {
                this.searchByOrgName(orgName, true);
            }, 500)
            return;
        }

        this.setState({
            loadingOrganisationResults: true
        })

        OrganisationApi.searchInternalByName(orgName).then((data) => {
            this.setState({
                possibleOrgMatches: data as PossibleOrgMatch[],
                loadingOrganisationResults: false
            })
        }).catch(() => {
            this.setState({
                loadingOrganisationResults: false
            })
        })
    }

    async saveProfile() {

        let missingFields: string[] = [];

        if (this.state.userType === UserType.Unknown) {
            missingFields.push("type");
        }

        if (!this.state.firstName || !this.state.lastName) {
            missingFields.push("name");
        }

        if (!this.state.phoneNumber) {
            missingFields.push("phone");
        }

        if (!this.state.headline) {
            missingFields.push("headline");
        }

        if (!this.state.newProfileImage) { // } && this.state.newProfileImagePreviewUrl === defaultProfileImage) {
            missingFields.push("user-photo");
        }

        this.setState({
            missingFields: missingFields
        })

        console.log(missingFields);

        if (missingFields.length > 0) {
            toast.error('Please fill in all required fields');
            return;
        }

        this.setState({ loading: true })

        // always going to have a new profile image
        const res = await new Promise((resolve) => {
            try {
                Utilities.resizeAndRotateImage(
                    this.state.newProfileImage as Blob
                ).then(async (newProfileImage) => {
                    if (newProfileImage) {
                        const res = await ProfileApi.updateProfileImage(newProfileImage, localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}").id : null);
                        resolve(res);
                    } else {
                        toast.error(
                            "This doesn't appear to be a valid image file, please choose another one"
                        );
                        resolve(null);
                    }
                }).catch(data => {
                    toast.error(
                        "This doesn't appear to be a valid image file, please choose another one"
                    );
                    resolve(null);
                })
            } catch (e) {
                toast.error(
                    "This doesn't appear to be a valid image file, please choose another one"
                );
                resolve(null);
            }
        })

        if (!this.state.skipOrgSetup) {
            this.setState({ step: this.state.userType === UserType.Worker ? "worker" : "organisation", loading: false })
        }

        ProfileApi.updateProfile({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            userTypeId: this.state.userType,
            headline: this.state.headline,
            phoneNumber: this.state.phoneNumber
            // termsAccepted: true,
            // privacyAccepted: true
        }).then( async () => {
            InternalTracker.trackEvent("Profile Saved from Onboarding");
            
            if (localStorage.getItem("user")) {
                const user = JSON.parse(localStorage.getItem("user") || "{}");
                user.userType = this.state.userType;
                user.firstName = this.state.firstName;
                user.lastName = this.state.lastName;
                if (user.settings && user.settings.find((setting) => setting.settingId === "17" && setting.value === "true")) {
                    localStorage.setItem("offersContinueOnWeekdays", "true")
                } else {
                    localStorage.setItem("offersContinueOnWeekdays", "false")
                }
                localStorage.setItem("user", JSON.stringify(user));
                profileActionCreators.getUserProfile()
            }

            if (this.state.userType !== UserType.Worker) {
                await ProfileApi.setType(this.state.userType as number);
            }

            if (this.state.skipOrgSetup) {
                window.location.href = '/availability';
            }
        }).catch(() => {
            toast.error('Failed to update profile.');
            this.setState({ step: "profile" })
        })
    }

    render() {
        return (
            <Subscribe to={[UserTypeContainer, DashboardContainer]}>
                {(container: UserTypeContainer, dashboardContainer: DashboardContainer) => {
                    if ((container.state.loaded && dashboardContainer.userProfileStateLoaded && (dashboardContainer.state.failed || dashboardContainer.state.loaded) ) || this.state.step === "waiting-verification") {
                        if (container.state.type === UserType.Unknown || !dashboardContainer.hasOrganisation) {
                            return (
                                <>
                                    <Mask>
                                        <Wrapper
                                            // ref={this.userTypeModal}
                                            className="layout vertical enhanced-signup"
                                            data-step={this.state.step}
                                        >
                                            <button 
                                                style={{ display: 'none' }}
                                                onClick={() => {
                                                    dashboardContainer.reload();
                                                    container.reload();
                                                    if ((window as any).dispatchRefreshUserProfile) {
                                                        (window as any).dispatchRefreshUserProfile();
                                                    }
                                                }}
                                                id="state-reload-button"
                                            ></button>
                                            { (this.state.step !== "waiting-verification") &&
                                                <React.Fragment>
                                                    <h2>{
                                                        this.state.step === "error" ? "An organisation with this name and address already exists - we'll be in touch shortly" : 
                                                        this.state.step === "worker" ? "To share your availability, select from the options below" : 
                                                        (this.state.step === "organisation" && this.state.possibleOrgMatch && this.state.possibleOrgMatch.selfSelected) ? "Join Organisation" :
                                                        (this.state.step === "organisation" && this.state.possibleOrgMatch && this.state.possibleOrgMatch.alreadyAskedToJoined) ? ("Request Sent to the admin of this Organisation" + (this.state.possibleOrgMatch.adminEmail ? ("(" + this.state.possibleOrgMatch.adminEmail + ")") : "")) :
                                                        (this.state.step === "organisation" && this.state.possibleOrgMatch && !this.state.possibleOrgMatch.alreadyAskedToJoined) ? "Is this your organisation?" :
                                                        "Almost There" 
                                                    }</h2>
                                                    { (this.state.step === "worker") &&
                                                        <h1>
                                                            You selected <strong>Worker</strong> as your user type
                                                            <Hyperlink
                                                                onClick={() =>
                                                                this.setState({
                                                                    step: "profile",
                                                                    userType: UserType.Unknown
                                                                })
                                                                }
                                                            >
                                                                Change your User Type
                                                            </Hyperlink>
                                                        </h1>
                                                    }
                                                    { (this.state.step !== "worker" && this.state.step !== "error" && !this.state.possibleOrgMatch?.alreadyAskedToJoined) &&
                                                        <p>{ this.state.possibleOrgMatch?.byNameLocation ? "An organisation with that name already has an account" : this.state.possibleOrgMatch?.byDomain ? "An organisation with this email domain already has an account" : this.state.userType === UserType.Unknown ? "Carefully select the user account type that best describes you" : "Review, or edit these details (needed to verify you)"}</p>
                                                    }
                                                </React.Fragment>
                                            }

                                            { (this.state.step === "error") &&
                                                <div style={{ textAlign: "center", marginBottom: 10 }}>
                                                    <Hyperlink
                                                        onClick={() => {
                                                            AuthApi.policyLogout();
                                                        }}
                                                    >
                                                        Log Out
                                                    </Hyperlink>
                                                </div>
                                            }
                                            
                                            { this.state.step === "profile" &&
                                                <React.Fragment>
                                                    {/* <div className='header-wrapper'>
                                                        <h3>About you</h3>
                                                    </div> */}
                                                    { (this.state.userType !== UserType.Unknown) &&
                                                        <div className='profile-setup'>
                                                            <div>
                                                                <label>
                                                                    Profile Photo
                                                                    <SimpleTooltip id="headline-info" placement="top" text="For contacts to recognize you - Use your social media image or a selfie. It is required & will be verified">
                                                                        <span className="tooltip-overlay">
                                                                            <i className="fa-solid fa-circle-info"></i>
                                                                        </span>
                                                                    </SimpleTooltip>
                                                                </label>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'left',
                                                                        alignItems: 'center',
                                                                        marginBottom: 15,
                                                                        width: '100%',
                                                                    }}
                                                                    className='user-photo-wrapper'
                                                                    data-error={this.state.missingFields.includes("user-photo") ? "true" : "false"}
                                                                >
                                                                    <ProfileImage
                                                                        selectable={false}
                                                                        type={'Organisation'}
                                                                        url={this.state.newProfileImagePreviewUrl}
                                                                        size={72}
                                                                        onSelect={() => {
                                                                            this.setState({
                                                                                newProfileImagePreviewUrl: ''
                                                                            }, () => {
                                                                                setTimeout(() => {
                                                                                    let el = document.getElementById('userProfileImageFile');
                                                                                    if (el) {
                                                                                        el.click();
                                                                                    }
                                                                                }, 200);
                                                                            });
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{ 
                                                                            flexBasis: 'calc(100% - 72px)',
                                                                            paddingLeft: 15,
                                                                            textAlign: 'left'
                                                                        }}
                                                                    >
                                                                        <p style={{
                                                                            fontSize: 12,
                                                                            margin: "0 0 8px 0"
                                                                        }}>Close up of your head and top of your shoulders so that your face takes up 70–80% of a sharp and clear photograph.</p>
                                                                        <button
                                                                            className="btn"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    newProfileImagePreviewUrl: ''
                                                                                }, () => {
                                                                                    setTimeout(() => {
                                                                                        let el = document.getElementById('userProfileImageFile');
                                                                                        if (el) {
                                                                                            el.click();
                                                                                        }
                                                                                    }, 200);
                                                                                });
                                                                            }}
                                                                            style={{
                                                                                background: theme.colours.updatedge,
                                                                                color: 'white',
                                                                                fontWeight: 700
                                                                            }}
                                                                        >
                                                                            Replace Photo
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    name="userProfileImageFile"
                                                                    id="userProfileImageFile"
                                                                    className="form-control"
                                                                    style={{ display: 'none' }}
                                                                    // disabled={verification.state.verified}
                                                                    onChange={(e) => {
                                                                        this.handleselectedFile(
                                                                            e.target.files
                                                                        )
                                                                        this.setState({
                                                                            missingFields: this.state.missingFields.filter(f => f !== "user-photo")
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flexBasis: '50%', marginRight: 12 }}>
                                                                    <label>First Name</label>
                                                                    <input 
                                                                        name="first-name"
                                                                        data-error={(this.state.missingFields.includes("name") && !this.state.firstName) ? "true" : "false"}
                                                                        value={this.state.firstName}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                firstName: (event.target as HTMLInputElement).value,
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{ flexBasis: '50%' }}>
                                                                    <label>Last Name</label>
                                                                    <input 
                                                                        name="last-name"
                                                                        data-error={(this.state.missingFields.includes("name") && !this.state.lastName) ? "true" : "false"}
                                                                        value={this.state.lastName}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                lastName: (event.target as HTMLInputElement).value
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Headline
                                                                    <SimpleTooltip id="headline-info" placement="top" text="Your role e.g. Headteacher, General Manager">
                                                                        <span className="tooltip-overlay">
                                                                            <i className="fa-solid fa-circle-info"></i>
                                                                        </span>
                                                                    </SimpleTooltip>
                                                                </label>
                                                                <input 
                                                                    name="headline"
                                                                    data-error={(this.state.missingFields.includes("headline") && !this.state.headline) ? "true" : "false"}
                                                                    value={this.state.headline}
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            headline: (event.target as HTMLInputElement).value
                                                                        })
                                                                    }}
                                                                />
                                                            </div>

                                                            <div style={{ marginBottom: 15 }}>
                                                                <div className='phone-number-wrapper' data-error={(this.state.missingFields.includes("phone") && !this.state.phoneNumber) ? "true" : "false"}>
                                                                    <label>
                                                                        Direct Phone Number
                                                                        (No one other than Updatedge sees this)
                                                                        <SimpleTooltip id="headline-info" placement="top" text="Used to Identify and Verify you we may send a code and or call you">
                                                                            <span className="tooltip-overlay">
                                                                                <i className="fa-solid fa-circle-info"></i>
                                                                            </span>
                                                                        </SimpleTooltip>
                                                                    </label>
                                                                    <PhoneInput
                                                                        preferredCountries={["US", "UK"]}
                                                                        enableLongNumbers={true}
                                                                        value={this.state ? ((!this.state.phoneNumber && !this.state.typedPhoneNumber) ? "+44" : !this.state.phoneNumber ? "" : this.state.phoneNumber) : "+44"}
                                                                        onChange={(phone) => {
                                                                            this.setState({
                                                                                phoneNumber: phone,
                                                                                typedPhoneNumber: true
                                                                            })
                                                                        }}
                                                                        onBlur={() => {
                                                                            InternalTracker.trackEvent('Phone Number Updated');
                                                                        }}
                                                                    />       
                                                                </div>
                                                            </div>
                                                        </div>
                                    }

                                                    { (!this.state.skipOrgSetup) &&
                                                        <div
                                                            className='user-type-selector' 
                                                            data-error={this.state.missingFields.includes("type") ? "true" : "false"}
                                                            style={{
                                                                marginBottom: this.state.userType === UserType.Unknown ? -10 : 0
                                                            }}
                                                        >
                                                            <label>User Account Type</label>
                                                            <InlineSelect
                                                                oneLine={true}
                                                                selected={(this.state.userType) + ""}
                                                                options={[
                                                                    { id: "1", icon: <i className="fas fa-user-clock" />, label: "Worker" },
                                                                    { id: "2", icon: <i className="fas fa-users" />, label: "Hirer" },
                                                                    { id: "3", icon: <i className="fas fa-users" />, label: "Agency" },
                                                                ]}
                                                                onSelect={(newAccess) => {
                                                                    this.setState({
                                                                        userType: newAccess === "2" ? UserType.Hirer : newAccess === "3" ? UserType.Agency : UserType.Worker,
                                                                        missingFields: this.state.missingFields.filter(f => f !== "type")
                                                                    })
                                                                    if (newAccess === "1") {
                                                                        InternalTracker.trackEvent("Worker Type Selected on Onboarding");
                                                                        this.setState({
                                                                            step: "worker"
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>   
                                                    }

                                                    { (this.state.userType !== UserType.Unknown) &&
                                                        <button onClick={() => {
                                                            this.saveProfile();
                                                        }} className='save-btn'>Confirm & Continue</button>   
                                                    }
                                                </React.Fragment>
                                            }
                                            { (this.state.step === "worker") &&
                                                <React.Fragment>
                                                    <div>
                                                        <div 
                                                            style={{
                                                                marginTop: 0
                                                            }} 
                                                            className="layout horizontal around-justified wrap m-t m-b"
                                                        >
                                                            <Tile className="layout vertical center flex" onClick={() => {
                                                                window.open("https://apps.apple.com/gb/app/id1559217533")
                                                            }}>
                                                                <label>Download iPhone/iPod/iPad App</label>
                                                                <div className="img">
                                                                    <img src="/img/ios-qr.png" />
                                                                </div>
                                                                <div className="img">
                                                                    <img src="/img/appstore.png" />
                                                                </div>
                                                            </Tile>
                                                            <Tile className="layout vertical center flex" onClick={() => {
                                                                window.open("https://play.google.com/store/apps/details?id=com.updatedge")
                                                            }}>
                                                                <label>Download Android Mobile App</label>
                                                                <div className="img">
                                                                    <img src="/img/android-qr.png" />
                                                                </div>
                                                                <div className="img">
                                                                    <img src="/img/googleplay.png" />
                                                                </div>
                                                            </Tile>
                                                            <Tile className="layout vertical center flex" onClick={() => {
                                                                window.open("https://my.updatedge.com")
                                                            }}>
                                                                <label>Use in any Browser / Computer</label>
                                                                <div className="img">
                                                                    <img src="/img/web-qr.png" />
                                                                </div>
                                                                <div className="img">
                                                                    <img src="/img/web.png" />
                                                                </div>
                                                            </Tile>
                                                        </div>
                                                        <div
                                                            className="m-t"
                                                            style={{
                                                                textAlign: 'center',
                                                                margin: '5px 0 5px 0'
                                                            }}
                                                        >
                                                            <Hyperlink
                                                                onClick={() => {
                                                                    AuthApi.policyLogout();
                                                                }}
                                                            >
                                                                Log Out
                                                            </Hyperlink>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            { this.state.step === "organisation" && this.state.possibleOrgMatch && this.state.possibleOrgMatch.alreadyAskedToJoined &&
                                                <React.Fragment>
                                                    <div className='buttons'>
                                                        <button style={{
                                                            margin: 0,
                                                            flexBasis: '100%',
                                                            marginTop: 12
                                                        }} className='save-btn' onClick={() => {
                                                            AuthApi.policyLogout();
                                                        }} >
                                                            <span>Log Out</span>
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            { this.state.step === "organisation" && this.state.possibleOrgMatch && !this.state.possibleOrgMatch.alreadyAskedToJoined &&
                                                <React.Fragment>
                                                    <div className='duplicate-org-header'>
                                                        <img 
                                                            src={CompanyApi.getOrganisationProfileImageUrl(this.state.possibleOrgMatch.id ? this.state.possibleOrgMatch.id.toString() : "")} 
                                                            style={{ width: 80, height: 80, borderRadius: '100%', objectFit: 'cover', marginBottom: 15 }} 
                                                        />
                                                        <h3>{this.state.possibleOrgMatch?.name}</h3>
                                                        <p style={{
                                                            marginBottom: 12,
                                                            fontWeight: 600
                                                        }}>The administrator is shown below</p>
                                                        <p style={{ marginBottom: 0, fontStyle: "italic" }}>{this.state.possibleOrgMatch?.adminName}</p>
                                                        <p style={{ fontStyle: "italic" }}>({this.state.possibleOrgMatch?.adminEmail})</p>
                                                        <div className='buttons' style={{ display: 'block', width: '100%' }}>
                                                            { (this.state.possibleOrgMatch) &&
                                                                <button style={{ display: 'block', width: '100%', margin: '0 0 10px 0', backgroundColor: theme.colours.blue2 }} className='save-btn' onClick={() => {
                                                                    window.open('mailto:support@updatedge.com?body=Hi, I need help joining my organisation (' + this.state.possibleOrgMatch?.name + ').')
                                                                }}>
                                                                    Contact Updatedge Support
                                                                </button>
                                                            }
                                                            <p style={{
                                                                marginBottom: 12,
                                                                fontWeight: 600
                                                            }}>If correct, would you like to:</p>
                                                            <button style={{ display: 'block', width: '100%', margin: '0 0 10px 0', backgroundColor: '#3D72E7' }} className='save-btn' onClick={ async () => {
                                                                this.setState({
                                                                    loading: true
                                                                })
                                                                await OrganisationApi.requestToJoinOrganisation(this.state.possibleOrgMatch?.id || "").then(async res => {
                                                                    if (this.state.possibleOrgMatch?.canJoinAutomatically) {
                                                                        if (localStorage.getItem("user")) {
                                                                            const user = JSON.parse(localStorage.getItem("user") || "{}");
                                                                            user.organisationId = this.state.possibleOrgMatch?.id;
                                                                            // user.organisationIsHirer = this.state.userType === UserType.Hirer;
                                                                            // user.organisationIsAgency = this.state.userType === UserType.Agency;
                                                                            localStorage.setItem("user", JSON.stringify(user));
                                                                            toast.success('You have joined the organisation');
                                                                            setTimeout(() => {
                                                                                window.location.reload();
                                                                            }, 1500)
                                                                        }
                                                                    } else {
                                                                        await this.fetchDuplicateOrg().catch(e => { }) // todo this won't fetch non-dmoain matched joins
                                                                        this.setState({
                                                                            loading: false
                                                                        })
                                                                    }
                                                                }).catch(e => {
                                                                    this.setState({
                                                                        loading: false
                                                                    })
                                                                })
                                                            }}>{this.state.possibleOrgMatch?.canJoinAutomatically ? "Join This Organisation" : "Yes, Ask to Join"}</button>
                                                            <button className='save-btn' style={{ display: 'block', width: '100%', margin: '0 0 0 0' }} onClick={() => {
                                                                if (this.state.possibleOrgMatch?.cannotOverride) {
                                                                    AuthApi.policyLogout();
                                                                } else {
                                                                    this.setState({
                                                                        possibleOrgMatch: null
                                                                    })
                                                                }
                                                            }} >
                                                                { (this.state.possibleOrgMatch.cannotOverride) ?
                                                                    <span>No, Log Out</span> :
                                                                    <span>Create A New Organisation</span>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            { this.state.step === "organisation" && !this.state.possibleOrgMatch &&
                                                <React.Fragment>
                                                    <div>
                                                        <label>
                                                            Organization Logo
                                                            <SimpleTooltip id="headline-info" placement="top" text="Required for contacts to know who they are dealing with">
                                                                <span className="tooltip-overlay">
                                                                    <i className="fa-solid fa-circle-info"></i>
                                                                </span>
                                                            </SimpleTooltip>
                                                        </label>
                                                        <div className='org-logo-wrapper'>
                                                            <div>
                                                                { (this.state.orgLogo || this.state.orgLogoPreview) &&
                                                                    <ProfileImage
                                                                        type="Organisation"
                                                                        url={this.state.orgLogoPreview || (typeof this.state.orgLogo === "string" ? this.state.orgLogo : "")}
                                                                        size={52}
                                                                        selectable={false}
                                                                        style={{
                                                                            border: 'none',
                                                                            borderRadius: '100%',
                                                                            padding: 0,
                                                                            marginRight: 8,
                                                                            objectFit: "cover",
                                                                            width: 52,
                                                                            height: 52
                                                                        }}
                                                                    />
                                                                }
                                                                <button 
                                                                    onClick={() => {
                                                                        document.getElementById("org-logo-input")?.click();   
                                                                    }}
                                                                    data-error={this.state.missingFields.includes("organization-logo") ? "true" : "false"}
                                                                >
                                                                        { this.state.orgLogo ? "Replace" : "Upload" }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            Organization Name
                                                            <SimpleTooltip id="headline-info" placement="top" text="Your contacts see the Organization name">
                                                                <span className="tooltip-overlay">
                                                                    <i className="fa-solid fa-circle-info"></i>
                                                                </span>
                                                            </SimpleTooltip>
                                                        </label>
                                                        <input name="organization-name"
                                                            data-error={(this.state.missingFields.includes("organization-name") && !this.state.orgName) ? "true" : "false"}
                                                            value={this.state.orgName}
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    orgName: (event.target as HTMLInputElement).value
                                                                }, () => {
                                                                    this.searchByOrgName(this.state.orgName);
                                                                })
                                                            }
                                                        } />    
                                                        { (this.state.possibleOrgMatches.length > 0 || this.state.loadingOrganisationResults) &&
                                                            <div className='suggestions'>
                                                                { (this.state.loadingOrganisationResults) &&
                                                                    <CircularProgress size={20} />
                                                                }
                                                                { this.state.possibleOrgMatches.map((org, i) => {
                                                                    return (
                                                                        <span 
                                                                            key={i}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    possibleOrgMatch: {
                                                                                        ...org,
                                                                                        selfSelected: true
                                                                                    },
                                                                                    possibleOrgMatches: []
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img src={CompanyApi.getOrganisationProfileImageUrl(org.id)} />
                                                                            <label>{org.name}</label>
                                                                        </span>
                                                                    )
                                                                })}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <label>
                                                            Organization Address
                                                            <SimpleTooltip id="headline-info" placement="top" text="Add details to your address to help people find you if not clear">
                                                                <span className="tooltip-overlay">
                                                                    <i className="fa-solid fa-circle-info"></i>
                                                                </span>
                                                            </SimpleTooltip>
                                                        </label>
                                                        <div
                                                            className='location-wrapper'
                                                            style={{ display: 'flex', marginBottom: 15 }}
                                                        >
                                                            { (this.state.locationPlaceId) &&
                                                                <input 
                                                                    name="organisation-address"
                                                                    value={(this.state.locationPlaceName ? (this.state.locationPlaceName + ", ") : "") + this.state.locationFullName}
                                                                    disabled={true}
                                                                    style={{ marginBottom: 0 }}
                                                                /> 
                                                            }
                                                            <button 
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showLocationPicker: true
                                                                    })
                                                                }}
                                                                style={{
                                                                    marginLeft:this.state.locationPlaceId ? 12 : undefined,
                                                                }}
                                                                data-error={this.state.missingFields.includes("organization-address") ? "true" : "false"}
                                                            >{ (this.state.locationPlaceId) ? "Change" : "Add Location" }</button>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }}>
                                                        <label data-error={this.state.missingFields.includes("sector") && this.state.sectors.length === 0}>
                                                            Sector
                                                            <SimpleTooltip id="headline-info" placement="top" text="Add your sector used to match skills to your organization">
                                                                <span className="tooltip-overlay">
                                                                    <i className="fa-solid fa-circle-info"></i>
                                                                </span>
                                                            </SimpleTooltip>
                                                        </label>
                                                        <IndustrySelector
                                                            minimal={this.state.sectors.length === 0}
                                                            aria-label="IndustrySelector"
                                                            enabled={true}
                                                            industries={this.state.sectors}
                                                            onSelected={(newSector) => {
                                                                this.setState({
                                                                    sectors: [...this.state.sectors, newSector]
                                                                })
                                                            }}
                                                            onRemoved={(id) => {
                                                                this.setState({
                                                                    sectors: this.state.sectors.filter(
                                                                        (industry) => id !== industry.id
                                                                    )
                                                                })
                                                            }}
                                                            searchPlacement="auto"
                                                            enableSuggestion={true}
                                                            hideInputAfterOne={true}
                                                        />
                                                    </div>
                                                    { this.state.sectors.map(
                                                        (sector, i) => {
                                                            if (!sector.subSectors) sector.subSectors = [];

                                                            if (this.state.userType === UserType.Agency) return null;

                                                            return (
                                                                <div 
                                                                    className="form-group subsector-wrapper"
                                                                    key={sector.id}
                                                                    style={{ marginBottom: 15 }}
                                                                    data-error={this.state.missingFields.includes("subsector") && sector.subSectors.length === 0}
                                                                >
                                                                    <label>
                                                                        Subsectors for {sector.name || sector.value}
                                                                        <SimpleTooltip id="headline-info" placement="top" text="Add your subsectors for better matching of detailed skills">
                                                                            <span className="tooltip-overlay">
                                                                                <i className="fa-solid fa-circle-info"></i>
                                                                            </span>
                                                                        </SimpleTooltip>
                                                                    </label>
                                                                    <IndustrySelector
                                                                        enabled={true}
                                                                        enableSuggestion={true}
                                                                        sectorId={sector.id}
                                                                        sectorName={sector.name || sector.value}
                                                                        industries={
                                                                            sector.subSectors ? sector.subSectors.map(item => { return { id: item.id, value: item.name || item.value || "", name: item.name || item.value || "" } }) : []
                                                                        }
                                                                        onSelected={(item) => {
                                                                            let industries = this.state.sectors
                                                                            if (industries && industries[i]) {
                                                                                if (!industries[i].subSectors) 
                                                                                    industries[i].subSectors = [];
                                                                                // @ts-ignore
                                                                                industries[i].subSectors.push({
                                                                                    name: item.value || item.name || "",
                                                                                    value: item.value || item.name || "",
                                                                                    id: item.id
                                                                                });
                                                                                this.setState({
                                                                                    sectors: industries
                                                                                });
                                                                            }
                                                                        }}
                                                                        onRemoved={(id) => {
                                                                            let industries = this.state.sectors;
                                                                            // @ts-ignore
                                                                            industries[i].subSectors = industries[i].subSectors.filter(item => item.id !== id);
                                                                            this.setState(
                                                                                (prevState) => ({
                                                                                    sectors: industries
                                                                                })
                                                                            );
                                                                        }}
                                                                        searchPlacement="auto"
                                                                        autoFocus={!this.state.disableSubsectorAutoFocus}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                    <div className='buttons'>
                                                        <button 
                                                            className='get-help-btn' 
                                                            onClick={() => {
                                                                Utilities.openChatWoot();
                                                            }}
                                                        >
                                                            <i className="fas fa-comment-alt"></i>
                                                            <span>Need Help? (Chat)</span>
                                                        </button>
                                                        <button className='save-btn' onClick={() => {
                                                            this.saveOrg();
                                                        }}>Confirm & Save</button>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            { this.state.step === "waiting-verification" &&
                                                <React.Fragment>
                                                    <div className='waiting-verification'>
                                                        <i className="fa-solid fa-hourglass-half"></i>
                                                        <p>Verification of your organization and details is now in progress.</p>
                                                        <p>Once Verified you will have access to additional features.</p>
                                                        {/* <p>Please be patient whilst we do this.</p> */}
                                                        <button onClick={() => {
                                                            if (this.state.userType === UserType.Hirer) {
                                                                window.location.href = "/invite"
                                                            } else {
                                                                window.location.reload();
                                                            }
                                                        }}>Explore the Portal</button>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </Wrapper>
                                    </Mask>
                                    <LocationPicker
                                        allowFriendlyName={false}
                                        show={this.state.showLocationPicker}
                                        onHide={() => {
                                            this.setState({
                                                showLocationPicker: false
                                            })
                                        }}
                                        onChange={(placeId, placeName, placeComponents, friendlyName, friendlyAddress) => {
                                            this.setState({
                                                locationFullName: friendlyAddress || "",
                                                locationPlaceId: placeId,
                                                locationPlaceName: placeName,
                                                locationAddressComponents: placeComponents,
                                                missingFields: this.state.missingFields.filter(f => f !== "organization-address")
                                            })
                                        }}
                                        placeId={
                                            /*this.state.newOrganisationFormData
                                                .locationPlaceId*/null
                                        }
                                    />
                                    <input
                                        type="file"
                                        name="org-logo-input"
                                        id="org-logo-input"
                                        className="form-control"
                                        style={{ marginTop: '0.5rem', display: 'none' }}
                                        onChange={ async (e) => {
                                            if (e.target.files && e.target.files.length !== 0) {
                                                this.setState({
                                                    orgLogoFile: e.target.files[0],
                                                    orgLogoPreview: URL.createObjectURL(
                                                        e.target.files[0]
                                                    ),
                                                    missingFields: this.state.missingFields.filter(f => f !== "organization-logo")
                                                })
                                            }
                                        }}
                                    />
                                    <FullScreenLoader noLoadingMessage={this.state.loading}  />
                                </>
                            )
                        } else if (container.state.type == UserType.Worker) {
                            return (
                                <>
                                    <Mask />
                                    <Wrapper
                                        // ref={this.workerModal}
                                        className="layout vertical worker-prompt"
                                    >
                                        <h3 style={{ fontWeight: 'bold' }}>
                                            This Portal is for organizations, business hirers, schools, and recruitment agencies. 
                                        </h3>
                                        <h4>
                                            To share your availability, select from the options below:
                                        </h4>
                                        <div className="layout horizontal around-justified wrap m-t m-b">
                                            <Tile className="layout vertical center flex" onClick={() => {
                                                window.open("https://apps.apple.com/gb/app/id1559217533")
                                            }}>
                                                <label>Download iPhone/iPod/iPad App</label>
                                                <div className="img">
                                                    <img src="/img/ios-qr.png" />
                                                </div>
                                                <div className="img">
                                                    <img src="/img/appstore.png" />
                                                </div>
                                            </Tile>
                                            <Tile className="layout vertical center flex" onClick={() => {
                                                window.open("https://play.google.com/store/apps/details?id=com.updatedge")
                                            }}>
                                                <label>Download Android Mobile App</label>
                                                <div className="img">
                                                    <img src="/img/android-qr.png" />
                                                </div>
                                                <div className="img">
                                                    <img src="/img/googleplay.png" />
                                                </div>
                                            </Tile>
                                            <Tile className="layout vertical center flex" onClick={() => {
                                                window.open("https://my.updatedge.com")
                                            }}>
                                                <label>Use in any Browser / Computer</label>
                                                <div className="img">
                                                    <img src="/img/web-qr.png" />
                                                </div>
                                                <div className="img">
                                                    <img src="/img/web.png" />
                                                </div>
                                            </Tile>
                                        </div>
                                        <div
                                            className="m-t"
                                            style={{
                                                textAlign: 'center',
                                                margin: '5px 0 5px 0'
                                            }}
                                        >
                                            <span
                                                style={{ marginTop: 16, display: 'block', color: 'blue' }}
                                                onClick={() => {
                                                    AuthApi.policyLogout();
                                                }}
                                            >
                                                Log Out
                                            </span>
                                        </div>
                                    </Wrapper>
                                </>
                            );
                        }
                    }

                    return null;
                }}
            </Subscribe>
        );
    }

    setType = (container: UserTypeContainer, type: UserType) => {
        container.setType(type);
    };
}

const AnimEnter = keyframes`
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const Mask = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background: #fff8;
`;

const Wrapper = styled.div`
    position: fixed;
    z-index: 1;
    padding: 15px;
    // overflow-y: auto;
    color: ${theme.colours.updatedge};
    text-align: center;
    background: white;
    border-radius: 7px;
    box-shadow: 0 0 250px 70px ${theme.colours.updatedge}88;
    transform: scale(0);
    opacity: 0;
    animation: 0.7s ${AnimEnter} ease-in-out both;

    .suggestions {
        text-align: left;
        margin-bottom: 10px;

        &>span {
            padding: 0;
            display: inline-flex;
            align-items: center;
            border-radius: 52px;
            margin: 0;
            display: inline-flex;
            cursor: pointer;

            label {
                margin: 0 10px 0 8px;
            }
            
            img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
            }

            &.MuiCircularProgress-root {
                background-color: transparent;
            }
        }
    }

    .thumbnail {
        overflow: hidden;
    }

    .react-tel-input {

        .form-control {
            width: 100%;
            border-color: #9a9a9a !important;
            padding-left: 55px;
        }
    }

    @media (min-width: 820px) {
        top: 40px;// 20vh;
        left: calc(50vw - 410px);
        max-height: calc(100vh - 80px);
        width: 820px;
        // min-height: 480px;
    }

    @media (max-width: 768px) {
        height: calc(100vh - 140px);
        top: 20px;
    }

    > h3 {
        font-size: 16px;
        font-weight: 700;
    }

    > h4 {
        font-size: 16px;
        font-weight: 500;
    }

    span.ue {
        color: ${theme.colours.green};
    }

    .img {
        width: 100%
    }

    img {
        width: 100%;
        cursor: pointer;
    }

    .buttons {
        display: flex;
        margin: -5px 0 0 0;

        button {
            flex-basis: calc(50% - 5px);

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    button {
        border: none;
        color: white;
        border-radius: 6px;
        padding: 8px 12px 8px 12px;
        font-weight: 600;
        font-size: 1.1em;

        &.get-help-btn {
            border: ${theme.colours.updatedge2} 1px solid;
            color: ${theme.colours.updatedge2};
            background: white;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 10px;
                display: inline-block;
            }
        }

        &.get-help-btn, &.save-btn {
            border-radius: 52px !important;
            padding: 12px;
        }
    }

    @media (max-width: 460px) {
        .worker-prompt {
            > h3 {
                font-size: 5vw;
            }

            > h4 {
                font-size: 4.5vw;
            }
        }
        div.mobile-downloads > div {
            text-align: center !important;
        }
    }

    .user-photo-wrapper[data-error="true"] {

        &>div:first-child {
            border-color: ${theme.colours.red2};
            background-color: #ffa1a0;
        }
    }

    .phone-number-wrapper[data-error="true"] {

        .react-tel-input .form-control {
            border-color: ${theme.colours.red2} !important;
            background-color: #ffa1a0;
        }
    }

    input[data-error="true"] {
        border-color: ${theme.colours.red2};
        background-color: #ffa1a0;
    }

    button[data-error="true"] {
        background-color:${theme.colours.red2};
    }

    label[data-error="true"] {
        color: ${theme.colours.red2};
    }

    .subsector-wrapper[data-error="true"]>div>div>div {
        border-color: ${theme.colours.red2};
        background-color: #ffa1a0;
    }

    label {

        .tooltip-overlay {
            margin-left: 6px;

            i {
                font-size: 16px;
            }
        }
    }

    .waiting-verification {

        i {
            font-size: 52px;
            color: ${theme.colours.green2};
            margin-bottom: 20px;
        }
        
        p {
            font-size: 1.2em;
            line-height: 1.4em;
            margin: 0 0 10px;
            font-weight: 600;

            &:nth-of-type(2) {
                font-weight: 400;
            }
        }

        button {
            background: ${theme.colours.blue2};
            color: white;
            font-weight: 600;
            padding: 10px 20px;
            width: 100%;
            border-radius: 52px;
            margin-top: 5px;
        }
    }
`;

const Confirm = styled.div`
    h4 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 16px;
    }

    p {
        font-size: 1.3em;
    }

    button {
        margin-top: 16px;
    }
`;

const Options = styled.div`
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        .img {
            flex-basis: 300px;
            img {
                width: 100%;
                height: unset;
                max-width: unset;
            }
        }
        .side {
            padding-left: 28px;
            flex-basis: calc(100% - 300px);
            text-align: left;
            display: inline-flex;
            flex-wrap: wrap;

            & > div {
                flex-basis: 100%;
                margin-bottom: 28px;

                h5 {
                    font-weight: 700;
                    font-size: 1.32em;
                }
                p {
                    font-size: 1.3em;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: 900px) {
            flex-wrap: wrap;
            .img, .side {
                padding: 0;
                flex-basis: 100%;

                &.img {
                    max-width: 360px;
                    margin: 0 auto 24px auto;
                }

                &.side {
                    text-align: center;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
`;

const Option = styled.div`
    margin: 20px;
    padding: 15px;
    box-shadow: 0 0 5px 0 ${theme.colours.updatedge}77;
    cursor: pointer;

    &:hover {
        background: #e6fae399;
        box-shadow: 0 0 7px 3px #92e28499;
    }

    &.selected {
        background: #e6fae3;
        box-shadow: 0 0 7px 3px #92e284;
    }

    > h5 {
        margin: 3vh 0;
        font-size: 22px;
    }

    > svg {
        width: 100px;
        height: 100px;
    }
`;

const Tile = styled.div`
    min-width: 180px;
    margin: 10px;
    background: whitesmoke;
    border-radius: 16px;
    padding: 10px;
    box-shadow: 0 0 5px 0 #a6a6a6cc;

    > label {
        margin-top: 10px;
        font-size: 18px;
    }

    @media (max-width: 460px) {
        margin: 10px;
        padding: 10px;
    }
`;
